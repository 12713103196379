import moment from 'moment';
import * as actionTypes from '../../constants/analytics.constant';
import { localStorageDecryption, localStorageEncryption } from '../../../utils';

const initialCommonFilter = {
    startDate: moment().subtract(1, 'month').startOf('days'),
    endDate: moment(),
}
const initialFuturFilter = {
    startDate: moment(),
    endDate: moment().add(1, 'month'),
}
const initialHeatMapFilter = {
    startDate: moment(),
    endDate: moment().add(6, 'days'),
}

const initialState = {

    openSidebarMenu: false,
    openTopHeader: false,
    openHeaderBtn: true,
    combine_leads: false,
    combine_leads_appointment: false,
    selectedTimeFrameOption: { label: "Week", value: 'Week' },
    selectedHeaderProceduresOptions: ['All treatments'],
    selectedCommonLeads: ['All Sources'],
    selectedCommonLocation: { label: 'All Location', value: 'All', surgeon: null },
    selectedCommonSurgeon: ["All"],
    selectedCommonWebPages: ['All webpages'],
    commonPayload: {
        user_role: '',
        server: '',
        leads: ['All Sources'],
        webPage: ['All webpages'],
        option: 'Week',
        location: 'All',
        surgeon: ["All"],
        procedures: ["All"],
        plans: [{ "label": "All insurance plans", "plan": "All plans", "value": 1000, "is_dynamic": false, "text_center_id": null }],
        utm_params: {
            source: ["All"],
            ad_group: ["All"],
            medium: ["All"],
            campaign: ["All"],
            id: ["All"],
            content: ["All"],
            term: ["All"],
        }
    },

    // ! Dot Options Dropdown
    isViablePatientDotOption: false,
    isShowedConsultReportDotOption: false,
    isShowedConsultAppointmentDotOption: false,
    isAvaliableAppointmentDotOption: false,
    isWebsiteConversionDotOption: false,
    isSchedulFirstPyramidChartDotOption: false,
    isInsuranceFirstPyramidChartDotOption: false,
    isBookedAppointmentHeatMapChartDotOption: false,
    isShowedAppointmentHeatMapChartDotOption: false,
    isShowRateAppointmentHeatMapChartDotOption: false,
    isClickedSchedulingBarChartDotOption: false,
    isFilledVsUnfilledAppointmentDotOption: false,
    isShowedConsultsPieDotOption: false,
    isUpcomingConsultsPieDotOption: false,
    isShowedConsultsByLeadSourceDotOption: false,
    isInsuranceReportFunnelChartDotOption: false,
    isPhoneFlowSchedulFirstPyramidChartDotOption: false,
    isPhoneFlowInsuranceFirstPyramidChartDotOption: false,
    isDailyOpenActionItemsReportChartDotOption: false,
    isOpenActionItemAverageTurnAroundTimeChartOption: false,
    isOpenActionItemsResolvedByUserChartDotOption: false,
    isfacebookAdsCostPerConversionFunnelOption: false,
    isFacebookAdsCostPerConversionOption: false,
    isGoogleAdsCostPerConversionOption: false,
    isGoogleAdsCostPerConversionFunnelOption: false,
    isOpenActionItemAverageTurnAroundTimeChartOption: false,
    isOpenActionItemsResolvedByUserChartDotOption: false,
    isclicktocallscheduledshowedconsultsDotOption: false,
    isclicktocallscheduledshowedconsultsbyleadsourceDotOption: false,
    isClicktocallscheduledshowedconsultsformsubmissiondateDotOption: false,
    isClicktocallfunnelDotOption: false,
    isClickToCallWebsiteConversionDotOption: false,
    isFollowUpImpactReportDotOption: false,
    isfacebookAdsCostPerConversionFunnelOption: false,
    isFacebookAdsCostPerConversionOption: false,
    isGoogleAdsCostPerConversionOption: false,
    isGoogleAdsCostPerConversionFunnelOption: false,
    isShowedTopPerformingAdsDotOption: false,
    isNonConvertingAdsDotOption: false,




    // ! Show Trend Line (only for LineChart)
    isViablePatientTrendLine: false,
    isShowedConsultReportTrendLine: false,
    isShowedConsultAppointmentTrendLine: false,
    isAvaliableAppointmentTrendLine: false,

    loader: false, // main loader

    // ! Chart Loader
    multiLineChartLoader: true,
    showedAndUpcomingConsultReportLoader: true,
    showedAndUpcomingConsultAppointmentLoader: true,
    avaliableAppointmentLineChartLoader: true,
    websiteConversionChartLoader: true,
    schedulFirstPyramidChartLoader: true,
    insuranceFirstPyramidChartLoader: true,
    gaugeChartLoader: true,
    bookedAppointmentHeatMapChartLoader: true,
    showedAppointmentHeatMapChartLoader: true,
    showRateAppointmentHeatMapChartLoader: true,
    clickedSchedulingBarChartLoader: true,
    filledVsUnfilledAppointmentLoader: true,
    showedConsultsByLeadSourceLoader: true,
    upcomingConsultsByLeadSourceLoader: true,
    showedVsNoShowVsCancelledAppointmentLoader: true,
    showedConsultsPieChartLoader: true,
    upcomingConsultsPieChartLoader: true,
    insuranceReportFunnelChartLoader: true,
    phoneFlowSchedulFirstPyramidChartLoader: true,
    phoneFlowInsuranceFirstPyramidChartLoader: true,
    showedAppointmentGoogleAnalyticsChartLoader: true,
    referralReportSchedulFirstPyramidChartLoader: true,
    referralReportInsuranceFirstPyramidChartLoader: true,
    ClickToCallScheduledShowedConsultsChartLoader: true,
    ClicktocallscheduledshowedconsultsbyleadsourceLoder: true,
    ClicktocallscheduledshowedconsultsformsubmissiondateLoder: true,
    ClicktocallfunnelLoader: true,
    FollowUpImpactReportChartLoader: true,
    ClicktocallwebsiteConversionLoader: true,
    FilledVsUnfilledFutureAppointmentSlotsPieChartLoader: true,
    OpenActionItemAverageTurnAroundTimeRequesLoader: true,
    dailyOpenActionItemsReportLoader: true,
    openActionItemsResolvedByUserLoader: true,
    FacebookAdsCostPerConversionLoader: true,
    facebookAdsCostPerConversionFunnelLoader: true,
    GoogleAdsCostPerConversionLoader: true,
    googleAdsCostPerConversionFunnelLoader: true,
    TopPerformingAdsLoader: true,
    NonConvertingAdsLoader: true,
    ConversionFunnelByInsuranceCompanyLoader: true,
    ScheduleFirstFunnelReportOverTimeLoader: true,
    InsuranceFirstFunnelReportOverTimeLoader: true,


    user: null,
    bssUsersOptions: null,

    textCenterId: null,
    textCenterIdResponse: null,
    textCenterIdOptions: null,

    currentPage: null,
    currentPageName: null,


    CTCFullHive: true,
    CTCSchedulingOnly: true,
    CTCClickToCall: true,
    LbMasterTogglePayload: null,

    businesShours: true,

    headerProceduresOptions: null,


    utm_source_options: [

    ],
    utm_ad_group_options: [

    ],
    utm_medium_options: [

    ],
    utm_campaign_options: [

    ],
    utm_id_options: [

    ],
    utm_content_options: [

    ],
    utm_term_options: [

    ],

    commonLeadsOptions: null,
    commonWebPagesOptions: [],
    commonInsurancePlansOptions: [],
    leadsScheduleFirstOptions: null,
    leadsOptionsForBookedHeatMap: null,
    leadsOptionsForShowedHeatMap: null,
    leadsOptionsForShowRateHeatMap: null,
    leadsOptionsForInsuranceFunnel: null,
    leadsOptionsForAvaliableAppointments: null,
    allOptions: null,

    globalFilter: initialCommonFilter,

    approvedFormFills: initialCommonFilter,
    showedAndUpcomingConsultReport: initialCommonFilter,
    showedAndUpcomingConsultAppointment: initialCommonFilter,
    avaliableAppointmentLineChart: initialCommonFilter,
    websiteConversion: initialCommonFilter,
    approvedScheduleFirstPyramid: initialCommonFilter,
    insuranceFirstPyramid: initialCommonFilter,
    bookedAppointmentHeatMapChart: initialCommonFilter,
    showedAppointmentHeatMapChart: initialCommonFilter,
    showRateAppointmentHeatMapChart: initialCommonFilter,
    filledVsUnfilledAppointment: initialCommonFilter,
    showedConsultsByLeadSource: initialCommonFilter,
    upcomingConsultsByLeadSource: initialCommonFilter,
    showedVsNoShowVsCancelledAppointment: initialCommonFilter,
    showedConsultsPieChart: initialCommonFilter,
    upcomingConsultsPieChart: initialCommonFilter,
    clickedSchedulingBarChart: initialCommonFilter,
    insuranceReportFunnel: initialCommonFilter,
    phoneFlowScheduleFirstPyramid: initialCommonFilter,
    phoneFlowInsuranceFirstPyramid: initialCommonFilter,
    showedAppointmentGoogleAnalytics: initialCommonFilter,
    referralReportScheduleFirstPyramid: initialCommonFilter,
    referralReportInsuranceFirstPyramid: initialCommonFilter,
    alertConversion: initialCommonFilter,
    alertCountConversion: initialCommonFilter,
    facebookConversion: initialCommonFilter,

    approvedFormFillsResponse: null,
    showedAndUpcomingConsultReportResponse: null,
    showedAndUpcomingConsultAppointmentResponse: null,
    avaliableAppointmentLineChartResponse: null,
    websiteConversionResponse: null,
    approvedScheduleFirstPyramidChartResponse: null,
    insuranceFirstPyramidChartResponse: null,
    bookedAppointmentHeatMapChartResponse: null,
    showedAppointmentHeatMapChartResponse: null,
    showRateAppointmentHeatMapChartResponse: null,
    filledVsUnfilledAppointmentResponse: null,
    showedConsultsByLeadSourceResponse: null,
    upcomingConsultsByLeadSourceResponse: null,
    showedVsNoShowVsCancelledAppointmentResponse: null,
    showedConsultsPieChartResponse: null,
    upcomingConsultsPieChartResponse: null,
    clickedSchedulingBarChartResponse: null,
    insuranceReportFunnelChartResponse: null,
    phoneFlowScheduleFirstPyramidChartResponse: null,
    phoneFlowInsuranceFirstPyramidChartResponse: null,
    showedAppointmentGoogleAnalyticsChartResponse: null,
    referralReportScheduleFirstPyramidChartResponse: null,
    referralReportInsuranceFirstPyramidChartResponse: null,
    alertResponse: null,
    alertCountResponse: null,
    FilledVsUnfilledFutureAppointmentSlotsPieChartResponse: null,
    ClickToCallScheduledShowedConsultsResponse: null,
    ClicktocallscheduledshowedconsultsbyleadsourceResponse: null,
    ClicktocallscheduledshowedconsultsformsubmissiondateResponse: null,
    ClicktocallfunnelResponse: null,
    FollowUpImpactReportChartResponse: null,
    ClicktocallwebsiteConversionResponse: null,
    OpenActionItemAverageTurnAroundTimeResponse: null,
    dailyOpenActionItemsReportResponse: null,
    OpenActionItemsResolvedByUserResponse: null,
    FacebookAdsCostPerConversionResponse: null,
    facebookAdsCostPerConversionFunnelResponse: null,
    facebookAppId: null,
    GoogleAdsCostPerConversionResponse: null,
    googleAdsCostPerConversionFunnelResponse: null,
    GoogleClientId: null,
    GooglecustomerIdForHeader: null,
    TopPerformingAdsResponse: null,
    TopPerFormingSelectedGoogleSourceOptions: { "key": "Google", "label": "Google Campaign Name", "value": "campaignName" },
    TopPerFormingSelectedFacebookSourceOptions: { "key": "Facebook", "label": "Facebook Campaign Name", "value": "campaignName" },
    TopPerFormingSelectedInstagramSourceOptions: { "key": "Instagram", "label": "Instagram Campaign Name", "value": "campaignName" },
    TopPerFormingSelectedGropsOptions: ["All"],
    NonConvertingAdsResponse: null,
    NonConvertinSelectedGoogleSourceOptions: { "key": "Google", "label": "Google Campaign Name", "value": "campaignName" },
    NonConvertinSelectedFacebookSourceOptions: { "key": "Facebook", "label": "Facebook Campaign Name", "value": "campaignName" },
    NonConvertinSelectedInstagramSourceOptions: { "key": "Instagram", "label": "Instagram Campaign Name", "value": "campaignName" },
    NonConvertinSelectedGropsOptions: ["All"],
    GoogleAuthDateReng: ["All"],
    MetaAuthDateReng: ["All"],
    FacebookSearchValue: '',
    ConversionFunnelByInsuranceCompanyResponse: null,
    ScheduleFirstFunnelReportOverTimeResponse: null,
    InsuranceFirstFunnelReportOverTimeResponse: null,

    approvedFormFillsError: null,
    showedAndUpcomingConsultReportError: null,
    showedAndUpcomingConsultAppointmentError: null,
    avaliableAppointmentLineChartError: null,
    websiteConversionError: null,
    approvedScheduleFirstPyramidChartError: null,
    insuranceFirstPyramidChartError: null,
    bookedAppointmentHeatMapChartError: null,
    showedAppointmentHeatMapChartError: null,
    showRateAppointmentHeatMapChartError: null,
    filledVsUnfilledAppointmentError: null,
    showedConsultsByLeadSourceError: null,
    upcomingConsultsByLeadSourceError: null,
    showedVsNoShowVsCancelledAppointmentError: null,
    showedConsultsPieChartError: null,
    upcomingConsultsPieChartError: null,
    clickedSchedulingBarChartError: null,
    phoneFlowScheduleFirstPyramidChartError: null,
    phoneFlowInsuranceFirstPyramidChartError: null,
    showedAppointmentGoogleAnalyticsChartError: null,
    referralReportScheduleFirstPyramidChartError: null,
    referralReportInsuranceFirstPyramidChartError: null,
    ClickToCallScheduledShowedConsultsError: null,
    ClicktocallscheduledshowedconsultsbyleadsourceError: null,
    ClicktocallscheduledshowedconsultsformsubmissiondateError: null,
    ClicktocallfunnelError: null,
    FollowUpImpactReportChartError: null,
    ClicktocallwebsiteConversionError: null,
    OpenActionItemAverageTurnAroundTimeError: null,
    dailyOpenActionItemsReportError: null,
    OpenActionItemsResolvedByUserError: null,
    FacebookAdsCostPerConversionError: null,
    facebookAdsCostPerConversionFunnelError: null,
    GoogleAdsCostPerConversionError: null,
    GoogleAdsCostPerConversionFunnelError: null,
    ConversionFunnelByInsuranceCompanyError: null,
    ScheduleFirstFunnelReportOverTimeError: null,
    InsuranceFirstFunnelReportOverTimeError: null,




    // ! --------------------------------- Location ---------------------------------
    // selectedLocationBooked: { label: 'All Location', value: 'All', surgeon: null },
    // selectedLocationShowed: { label: 'All Location', value: 'All', surgeon: null },
    // selectedLocationShowRate: { label: 'All Location', value: 'All', surgeon: null },
    // selectedLocationFilledVsUnfilled: { label: 'All Location', value: 'All', surgeon: null },
    // selectedLocationShowedConsultsByLeadSource: { label: 'All Location', value: 'All', surgeon: null },
    // selectedLocationUpcomingConsultsByLeadSource: { label: 'All Location', value: 'All', surgeon: null },
    // selectedLocationShowedConsultsPieChart: { label: 'All Location', value: 'All', surgeon: null },
    // selectedLocationUpcomingConsultsPieChart: { label: 'All Location', value: 'All', surgeon: null },
    // selectedLocationShowedVsNoShowVsCancelled: { label: 'All Location', value: 'All', surgeon: null },
    // selectedLocationClickedBar: { label: 'All Location', value: 'All', surgeon: null },

    // ! --------------------------------- Gauge Chart ---------------------------------
    // Get Practice Location Options
    practiceLocationOptions: null,
    selectedPracticeLocationOption: null,
    practiceLocationError: null,
    // Get Surgeons Option
    surgeonsOption: null,
    surgeonsError: null,
    //Get Gauge Chart Api Value
    gaugeChart: { ...initialCommonFilter, selectedSurgeonsOption: { value: '', location_id: '', label: 'Select...' } },
    gaugeChartResponse: null,
    gaugeChartError: null,




};

const toggleSidebarHandler = (state, action) => ({ ...state, openSidebarMenu: !state.openSidebarMenu })

const toggleHeaderBtnHandler = (state, action) => {
    let { data } = action;
    commonLocalStorageUpdateHandler('localState', 'openHeaderBtn', !data);
    return { ...state, openHeaderBtn: !data }
}
const CTCFullHiveToggleHandlerBtn = (state, action) => {
    let { data } = action;
    data = data === 0 ? true : false
    return { ...state, CTCFullHive: data }
}
const CTCSchedulingOnlyToggleHandlerBtn = (state, action) => {
    let { data } = action;
    data = data === 0 ? true : false
    return { ...state, CTCSchedulingOnly: data }
}
const CTCSClickToCallToggleHandlerBtn = (state, action) => {
    let { data } = action;
    data = data === 0 ? true : false
    return { ...state, CTCClickToCall: data }
}
const CTCSelectedTogglePayloadHandler = (state, action) => {
    let { payload } = action;
    return { ...state, LbMasterTogglePayload: payload }
}

const businesshoursBtnHandler = (state, action) => {
    let { data } = action;
    data = data === 0 ? false : true
    return { ...state, businesShours: data }
}

const toggleTopHeaderHandler = (state, action) => ({ ...state, openTopHeader: !state.openTopHeader })

const toggleDotsHeaderHandler = (state, action) => {

    const { data } = action

    return { ...state, [data]: !state[data] }
}

const authenticationRequestHandler = (state, action) => ({ ...state, loader: true });

const authenticationdResponseHandler = (state, action) => {


    const { data } = action.data;
    if (data.user_role === 'practice' && data.text_center_id === 48) { // This condition for default view Lap-Band Reports
        data.sub_menu = data?.sub_menu.filter((item) => item.name === 'Lap-Band Reports')
    }
    let globalFilter = initialCommonFilter
    if (data.inception) {
        globalFilter = {
            startDate: moment(data.inception),
            endDate: moment(),
        }
    }
    return {
        ...state,
        loader: false,
        user: data,
        commonPayload: {
            ...state.commonPayload,
            user_role: data.user_role,
            server: data.server,
            plans: [{ "label": "All insurance plans", "plan": "All plans", "value": 1000, "is_dynamic": false, "text_center_id": null }],


        }, globalFilter,
        approvedFormFills: {
            ...globalFilter,
        },
        showedAndUpcomingConsultReport: globalFilter,
        showedAndUpcomingConsultAppointment: {
            ...globalFilter,
        },
        avaliableAppointmentLineChart: {
            ...globalFilter,
        },
        approvedScheduleFirstPyramid: { ...globalFilter, },
        insuranceFirstPyramid: { ...globalFilter },
        bookedAppointmentHeatMapChart: {
            ...globalFilter,
        },
        showedAppointmentHeatMapChart: {
            ...globalFilter,
        },
        showRateAppointmentHeatMapChart: {
            ...globalFilter,
        },
        filledVsUnfilledAppointment: {
            ...globalFilter,
        },
        showedConsultsByLeadSource: {
            ...globalFilter,
        },
        upcomingConsultsByLeadSource: {
            ...globalFilter,
        },
        showedVsNoShowVsCancelledAppointment: {
            ...globalFilter,
        },
        showedConsultsPieChart: {
            ...globalFilter,
        },
        upcomingConsultsPieChart: {
            ...globalFilter,
        },
        clickedSchedulingBarChart: {
            ...globalFilter,
        },
        insuranceReportFunnel: { ...globalFilter },
        phoneFlowScheduleFirstPyramid: globalFilter,
        phoneFlowInsuranceFirstPyramid: globalFilter,
        showedAppointmentGoogleAnalytics: globalFilter,
        referralReportScheduleFirstPyramid: globalFilter,
        referralReportInsuranceFirstPyramid: globalFilter,
        facebookConversion: {
            ...globalFilter,
        },
        currentPage: data.sub_menu[0].page
    }
};

const authenticationErrorHandler = (state, action) => {
    return { ...state, loader: false }
};

const getBssUsersViewResponseHandler = (state, action) => {
    const { data } = action;
    return { ...state, loader: false, bssUsersOptions: data }
};

const textCenterIdRequestHandler = (state, action) => ({ ...state, loader: true })

const textCenterIdResponseHandler = (state, action) => {
    const { data } = action;
    // Modify the data before assigning it to textCenterIdOptions
    const modifiedOptions = data.data.DataFromDB.map(item => {
        // console.log(item);
        if (item.id === 584) { // Change the ID to the one you want to update
            return { ...item, name: "Hive Analytics Demo", id: 568 }; // Update the name property
        } else {
            return item;
        }
    }); return { ...state, textCenterIdResponse: data.data, textCenterIdOptions: modifiedOptions, loader: false }
}

const textCenterIdErrorHandler = (state, action) => ({ ...state, loader: false })

// leads common option array 
const leadsCommonOptionRequestHandler = (state, action) => ({ ...state, })
const leadsCommonOptionResponseHandler = (state, action) => {
    const { data } = action;
    data.forEach(entry => {
        // This is For "Google AdWords" Word In leds Option  Changes into "Google Ads"
        if (entry.label.includes("Google AdWords")) {
            entry.label = entry.label.replace("Google AdWords", "Google Ads");
        }
    });
    return { ...state, commonLeadsOptions: data, }
}
const leadsCommonOptionErrorHandler = (state, action) => ({ ...state, })

// webPages options
const webPagesCommonOptionResponseHandler = (state, action) => {
    const { data } = action.data;
    data.forEach(entry => {
        // This is For "Google AdWords" Word In leds Option  Changes into "Google Ads"
        if (entry.label.includes("Google AdWords")) {
            entry.label = entry.label.replace("Google AdWords", "Google Ads");
        }
    });
    return { ...state, commonWebPagesOptions: data, }
}

// InsurancePlans options
const insurancePlansCommonOptionResponseHandler = (state, action) => {
    const { data } = action.data;
    return { ...state, commonInsurancePlansOptions: data, }
}
const getAllOptionResponseHandler = (state, action) => {
    const { data } = action.data;
    return { ...state, allOptions: data, }
}

// get Procedures option array
const headerProceduresOptionResponseHandler = (state, action) => {
    const { data } = action;
    return { ...state, headerProceduresOptions: data, }
}
const headerProceduresOptionErrorHandler = (state, action) => ({ ...state, })
// leads InsuranceFirst option array 


// UTM toggle Options
const utmToggleOptionResponseHandler = (state, action) => {
    const { data } = action.data;
    return { ...state, ...data }
}




const chartAnalyticsRequestHandler = (state, action) => {
    return {
        ...state,
        multiLineChartLoader: true,
        showedAndUpcomingConsultReportLoader: true,
        schedulFirstPyramidChartLoader: true,
        insuranceFirstPyramidChartLoader: true,
        gaugeChartLoader: true,
        bookedAppointmentHeatMapChartLoader: true,
        showedAppointmentHeatMapChartLoader: true,
        insuranceReportFunnelChartLoader: true,
        phoneFlowSchedulFirstPyramidChartLoader: true,
        phoneFlowInsuranceFirstPyramidChartLoader: true,
        showedAppointmentGoogleAnalyticsChartLoader: true,
        referralReportSchedulFirstPyramidChartLoader: true,
        referralReportInsuranceFirstPyramidChartLoader: true,
        globalFilterFlag: false,
    }
}

const chartAnalyticsResponseHandler = (state, action) => {
    const { response } = action;
    const [
        approvedFormFills,
        showedAndUpcomingConsultReport,
        approvedScheduleFirstPyramid,
        insuranceFirstPyramid,
        gaugeChart,
        bookedAppointmentHeatMapChart,
        showedAppointmentHeatMapChart,
        insuranceReportFunnel,
        phoneFlowScheduleFirstPyramid,
        phoneFlowInsuranceFirstPyramid,
        showedAppointmentGoogleAnalytics,
        referralReportScheduleFirstPyramid,
        referralReportInsuranceFirstPyramid,
        facebookConversion,
    ] = response;
    return {
        ...state,
        multiLineChartLoader: false,
        showedAndUpcomingConsultReportLoader: false,
        schedulFirstPyramidChartLoader: false,
        insuranceFirstPyramidChartLoader: false,
        gaugeChartLoader: false,
        bookedAppointmentHeatMapChartLoader: false,
        showedAppointmentHeatMapChartLoader: false,
        insuranceReportFunnelChartLoader: false,
        phoneFlowSchedulFirstPyramidChartLoader: false,
        phoneFlowInsuranceFirstPyramidChartLoader: false,
        showedAppointmentGoogleAnalyticsChartLoader: false,
        referralReportSchedulFirstPyramidChartLoader: false,
        referralReportInsuranceFirstPyramidChartLoader: false,
        approvedFormFillsResponse: approvedFormFills.data,
        showedAndUpcomingConsultReportResponse: showedAndUpcomingConsultReport.data,
        approvedScheduleFirstPyramidChartResponse: approvedScheduleFirstPyramid.data,
        insuranceFirstPyramidChartResponse: insuranceFirstPyramid.data,
        gaugeChartResponse: gaugeChart.data,
        bookedAppointmentHeatMapChartResponse: bookedAppointmentHeatMapChart?.data,
        showedAppointmentHeatMapChartResponse: showedAppointmentHeatMapChart?.data,
        insuranceReportFunnelChartResponse: insuranceReportFunnel?.data,
        phoneFlowScheduleFirstPyramidChartResponse: phoneFlowScheduleFirstPyramid?.data,
        phoneFlowInsuranceFirstPyramidChartResponse: phoneFlowInsuranceFirstPyramid?.data,
        showedAppointmentGoogleAnalyticsChartResponse: showedAppointmentGoogleAnalytics?.data,
        referralReportScheduleFirstPyramidChartResponse: referralReportScheduleFirstPyramid?.data,
        referralReportInsuranceFirstPyramidChartResponse: referralReportInsuranceFirstPyramid?.data,
        FacebookAdsCostPerConversionResponse: facebookConversion.data,
        alertsChartLoder: false,

    }
}

const chartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    return {
        ...state,
        multiLineChartLoader: false,
        showedAndUpcomingConsultReportLoader: false,
        schedulFirstPyramidChartLoader: false,
        insuranceFirstPyramidChartLoader: false,
        gaugeChartLoader: false,
        bookedAppointmentHeatMapChartLoader: false,
        showedAppointmentHeatMapChartLoader: false,
        insuranceReportFunnelChartLoader: false,
        phoneFlowSchedulFirstPyramidChartLoader: false,
        phoneFlowInsuranceFirstPyramidChartLoader: false,
        showedAppointmentGoogleAnalyticsChartLoader: false,
        referralReportSchedulFirstPyramidChartLoader: false,
        referralReportInsuranceFirstPyramidChartLoader: false,
        approvedFormFillsError: data,


    }
}

const multiLineChartAnalyticsRequestHandler = (state, action) => ({ ...state, multiLineChartLoader: true, globalFilterFlag: false });

const commonLocalStorageUpdateHandler = (key, subKey, payload) => {
    let localState = localStorageDecryption(key) ? localStorageDecryption(key) : {};
    localState[subKey] = payload;
    localStorageEncryption(key, localState);
}

const multiLineChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const approvedFormFills = response;
    const { data } = approvedFormFills;
    const nextState = { ...state, multiLineChartLoader: false, approvedFormFillsResponse: data, approvedFormFills: payload };
    commonLocalStorageUpdateHandler('localState', 'approvedFormFills', payload)
    return nextState
}

const multiLineChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    return { ...state, multiLineChartLoader: false, approvedFormFillsError: data }
}

// Showed & Upcoming Consult Report
const showedAndUpcomingConsultReportRequestHandler = (state, action) => ({ ...state, showedAndUpcomingConsultReportLoader: true, globalFilterFlag: false });

const showedAndUpcomingConsultReportResponseHandler = (state, action) => {
    const { response, payload } = action;
    const showedAndUpcomingConsultReport = response;
    const { data } = showedAndUpcomingConsultReport;

    //! Make this Code Change Name Like Google AdWords To Google Ads In Key And Value Both Start here
    // Iterate through the data array
    data.data.forEach(entry => {
        // Iterate through the keys of each entry
        Object.keys(entry).forEach(key => {
            // Check if the key contains "Google AdWords"
            if (key.includes("Google AdWords")) {
                // Replace "Google AdWords" with "Google Ads"
                const updatedKey = key.replace("Google AdWords", "Google Ads");
                // Assign the value of the current key to the updated key
                entry[updatedKey] = entry[key];
                // Delete the old key
                delete entry[key];
            } else if (typeof entry[key] === 'string' && entry[key].includes("Google AdWords")) {
                // Check if the value is a string containing "Google AdWords"
                entry[key] = entry[key].replace("Google AdWords", "Google Ads");
            }
        });
    });

    // Iterate through the seriesArray
    data.seriesArray.forEach(series => {
        // Check if the title contains "Google AdWords"
        if (series.title.includes("Google AdWords")) {
            // Replace "Google AdWords" with "Google Ads"
            series.title = series.title.replace("Google AdWords", "Google Ads");
        }
    });
    //! End here changes Code 

    const nextState = { ...state, showedAndUpcomingConsultReportLoader: false, showedAndUpcomingConsultReportResponse: data, showedAndUpcomingConsultReport: payload };
    commonLocalStorageUpdateHandler('localState', 'showedAndUpcomingConsultReport', payload)
    return nextState
}

const showedAndUpcomingConsultReportErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    return { ...state, showedAndUpcomingConsultReportLoader: false, showedAndUpcomingConsultReportError: data }
}

const togglecombineleadsBtnHandler = (state, action) => {
    let { data } = action;
    data = data === 0 ? false : true
    return { ...state, combine_leads: data }
}

// Showed & Upcoming Consult Report
const showedAndUpcomingConsultAppointmentRequestHandler = (state, action) => ({ ...state, showedAndUpcomingConsultAppointmentLoader: true, globalFilterFlag: false });

const showedAndUpcomingConsultAppointmentResponseHandler = (state, action) => {
    const { response, payload } = action;
    const showedAndUpcomingConsultAppointment = response;
    const { data } = showedAndUpcomingConsultAppointment;
    //! Make this Code Change Name Like Google AdWords To Google Ads In Key And Value Both Start here
    // Iterate through the data array
    data.data.forEach(entry => {
        // Iterate through the keys of each entry
        Object.keys(entry).forEach(key => {
            // Check if the key contains "Google AdWords"
            if (key.includes("Google AdWords")) {
                // Replace "Google AdWords" with "Google Ads"
                const updatedKey = key.replace("Google AdWords", "Google Ads");
                // Assign the value of the current key to the updated key
                entry[updatedKey] = entry[key];
                // Delete the old key
                delete entry[key];
            } else if (typeof entry[key] === 'string' && entry[key].includes("Google AdWords")) {
                // Check if the value is a string containing "Google AdWords"
                entry[key] = entry[key].replace("Google AdWords", "Google Ads");
            }
        });
    });

    // Iterate through the seriesArray
    data.seriesArray.forEach(series => {
        // Check if the title contains "Google AdWords"
        if (series.title.includes("Google AdWords")) {
            // Replace "Google AdWords" with "Google Ads"
            series.title = series.title.replace("Google AdWords", "Google Ads");
        }
    });
    //! End here changes Code 

    const nextState = { ...state, showedAndUpcomingConsultAppointmentLoader: false, showedAndUpcomingConsultAppointmentResponse: data, showedAndUpcomingConsultAppointment: payload };
    commonLocalStorageUpdateHandler('localState', 'showedAndUpcomingConsultAppointment', payload)
    return nextState
}

const showedAndUpcomingConsultAppointmentErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    return { ...state, showedAndUpcomingConsultAppointmentLoader: false, showedAndUpcomingConsultAppointmentError: data }
}

const togglecombineleadsAppointmentBtnHandler = (state, action) => {
    let { data } = action;
    data = data === 0 ? false : true
    return { ...state, combine_leads_appointment: data }
}


//daily_open_action_items_report
const dailyOpenActionItemsReportRequestHandler = (state, action) => ({ ...state, dailyOpenActionItemsReportLoader: true })

const dailyOpenActionItemsReportRequestResponseHandler = (state, action) => {

    const { response } = action;
    const { data } = response
    const nextState = { ...state, dailyOpenActionItemsReportLoader: false, dailyOpenActionItemsReportResponse: data };
    return nextState
}

const dailyOpenActionItemsReportErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    return { ...state, dailyOpenActionItemsReportLoader: false, dailyOpenActionItemsReportError: data }
}






// avaliable Appointment
const avaliableAppointmentLineChartRequestHandler = (state, action) => ({ ...state, avaliableAppointmentLineChartLoader: true, globalFilterFlag: false });

const avaliableAppointmentLineChartResponseHandler = (state, action) => {
    const { response, payload } = action;
    const avaliableAppointment = response;
    const { data } = avaliableAppointment;
    const nextState = { ...state, avaliableAppointmentLineChartLoader: false, avaliableAppointmentLineChartResponse: data, avaliableAppointmentLineChart: payload };
    commonLocalStorageUpdateHandler('localState', 'avaliableAppointmentLineChart', payload)
    return nextState
}

const avaliableAppointmentLineChartErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    return { ...state, avaliableAppointmentLineChartLoader: false, avaliableAppointmentLineChartError: data }
}

// leads ScheduleFirst option array 

// ! Multivalue Axis data (multiple y axis)
const websiteConversionRequestHandler = (state, action) => ({ ...state, websiteConversionChartLoader: true, globalFilterFlag: false });

const websiteConversionResponseHandler = (state, action) => {
    const { response, payload } = action;
    const websiteConversion = response;
    const { data } = websiteConversion;
    const nextState = { ...state, websiteConversionChartLoader: false, websiteConversionResponse: data, websiteConversion: payload };
    commonLocalStorageUpdateHandler('localState', 'websiteConversion', payload)
    return nextState
}

const websiteConversionErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    const temp = [...state.websiteConversionResponse];
    return { ...state, websiteConversionChartLoader: false, websiteConversionError: data, websiteConversionResponse: { ...temp, isForm: data.isForm } }
}

// ! ----------------------- Get Alerts Data ----------------------------------------------------------------


const alertRequestHandler = (state, action) => {
    return ({ ...state, loader: true, globalFilterFlag: false })
}

const alertResponseHandler = (state, action) => {
    const { response, payload } = action;
    const alertConversion = response;
    const { data } = alertConversion;
    const nextState = {
        ...state, loader: false, alertResponse: data, alertConversion: payload, alertCountResponse: { alert: data.alerts }
    };
    commonLocalStorageUpdateHandler('localState', 'alertConversion', payload)
    return nextState
}
const alertResponseHandlerErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, alertLineChartError: data }
}

const alertCountHandler = (state, action) => {
    // const { alert } = action.response;
    return { ...state, alertCountResponse: action.response }
}

// ! --------------------------------- Pyramid Chart ---------------------------------
// SchedulFirst PyramidChart
const schedulFirstPyramidChartAnalyticsRequestHandler = (state, action) => ({ ...state, schedulFirstPyramidChartLoader: true, globalFilterFlag: false })

const schedulFirstPyramidChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const approvedScheduleFirstPyramidFormFills = response;
    const { data } = approvedScheduleFirstPyramidFormFills;
    commonLocalStorageUpdateHandler('localState', 'approvedScheduleFirstPyramid', payload)
    return { ...state, schedulFirstPyramidChartLoader: false, approvedScheduleFirstPyramidChartResponse: data, approvedScheduleFirstPyramid: payload }
}

const schedulFirstPyramidChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, schedulFirstPyramidChartLoader: false, approvedScheduleFirstPyramidChartError: data }
}

// InsuranceFirst PyramidChart
const insuranceFirstPyramidChartAnalyticsRequestHandler = (state, action) => ({ ...state, insuranceFirstPyramidChartLoader: true, globalFilterFlag: false })

const insuranceFirstPyramidChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const approvedScheduleFirstPyramidFormFills = response;
    const { data } = approvedScheduleFirstPyramidFormFills;
    commonLocalStorageUpdateHandler('localState', 'insuranceFirstPyramid', payload)
    return { ...state, insuranceFirstPyramidChartLoader: false, insuranceFirstPyramidChartResponse: data, insuranceFirstPyramid: payload }
}

const insuranceFirstPyramidChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, insuranceFirstPyramidChartLoader: false, insuranceFirstPyramidChartError: data }
}

// ! --------------------------------- Gauge Chart ---------------------------------
// Get Practice Location Api Call
const getPracticeLocationsApiRequestHandler = (state, action) => ({ ...state, gaugeChartLoader: true, globalFilterFlag: false })

const getPracticeLocationsApiResponseHandler = (state, action) => {
    const { response } = action;
    return { ...state, gaugeChartLoader: false, practiceLocationOptions: response, }
}

const getPracticeLocationsApiErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, gaugeChartLoader: false, practiceLocationError: data }
}

// get Surgeons Api Call
const getSurgeonsRequestHandler = (state, action) => ({ ...state, globalFilterFlag: false })

const getSurgeonsApiResponseHandler = (state, action) => {
    const { response, payload } = action;
    const data = response?.data;
    // commonLocalStorageUpdateHandler('localState', 'surgeonsOption', data)
    return { ...state, surgeonsOption: data }
}

const getSurgeonsApiErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, gaugeChartLoader: false, surgeonsError: data }
}

// get Gauge chart Value Api Call
const getGaugeChartRequestHandler = (state, action) => ({ ...state, gaugeChartLoader: true, globalFilterFlag: false })

const getGaugeChartResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'gaugeChart', payload)
    return { ...state, gaugeChartLoader: false, gaugeChartResponse: data, gaugeChart: payload }
}

const getGaugeChartErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, gaugeChartLoader: false, gaugeChartError: data }
}

// ! --------------------------------- Heat Map Chart ---------------------------------
// get Booked Appointment Heat Map Chart
const bookedAppointmentHeatMapChartAnalyticsRequestHandler = (state, action) => ({ ...state, bookedAppointmentHeatMapChartLoader: true, globalFilterFlag: false })
const bookedAppointmentHeatMapChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'bookedAppointmentHeatMapChart', payload)
    return { ...state, bookedAppointmentHeatMapChartLoader: false, bookedAppointmentHeatMapChartResponse: data, bookedAppointmentHeatMapChart: payload }
}
const bookedAppointmentHeatMapChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    const nextState = { ...state, bookedAppointmentHeatMapChartLoader: false, bookedAppointmentHeatMapChartError: data }
    return nextState
}

const selectedLocationBookedResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationBooked: data,
        bookedAppointmentHeatMapChart: {
            ...state.bookedAppointmentHeatMapChart,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }
    }
}

// leads ScheduleFirst option array
const leadsOptionBookedRequestHandler = (state, action) => ({ ...state, bookedAppointmentHeatMapChartLoader: true })
const leadsOptionBookedResponseHandler = (state, action) => {
    const { data } = action;
    return { ...state, leadsOptionsForBookedHeatMap: data.data, bookedAppointmentHeatMapChartLoader: false }
}
const leadsOptionBookedErrorHandler = (state, action) => ({ ...state, bookedAppointmentHeatMapChartLoader: false })

// get Showed Appointment Heat Map Chart
const showedAppointmentHeatMapChartAnalyticsRequestHandler = (state, action) => ({ ...state, showedAppointmentHeatMapChartLoader: true, globalFilterFlag: false })
const showedAppointmentHeatMapChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    const nextState = {
        ...state,
        showedAppointmentHeatMapChartLoader: false,
        showedAppointmentHeatMapChartResponse: data,
        showedAppointmentHeatMapChart: payload
    }
    commonLocalStorageUpdateHandler('localState', 'showedAppointmentHeatMapChart', payload)
    return nextState
}
const showedAppointmentHeatMapChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    const nextState = { ...state, showedAppointmentHeatMapChartLoader: false, showedAppointmentHeatMapChartError: data }
    return nextState
}

const selectedLocationShowedResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationShowed: data,
        showedAppointmentHeatMapChart: {
            ...state.showedAppointmentHeatMapChart,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }
    }
}

// leads option for ShowedAppointmentHeatMapChart 
const leadsOptionShowedRequestHandler = (state, action) => ({ ...state, showedAppointmentHeatMapChartLoader: true })
const leadsOptionShowedResponseHandler = (state, action) => {
    const { data } = action;
    return { ...state, leadsOptionsForShowedHeatMap: data.data, showedAppointmentHeatMapChartLoader: false }
}
const leadsOptionShowedErrorHandler = (state, action) => ({ ...state, showedAppointmentHeatMapChartLoader: false })

// get Show Rate Appointment Heat Map Chart
const showRateAppointmentHeatMapChartAnalyticsRequestHandler = (state, action) => ({ ...state, showRateAppointmentHeatMapChartLoader: true, globalFilterFlag: false })
const showRateAppointmentHeatMapChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    const nextState = { ...state, showRateAppointmentHeatMapChartLoader: false, showRateAppointmentHeatMapChartResponse: data, showRateAppointmentHeatMapChart: payload }
    commonLocalStorageUpdateHandler('localState', 'showRateAppointmentHeatMapChart', payload)
    return nextState
}
const showRateAppointmentHeatMapChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    const nextState = { ...state, showRateAppointmentHeatMapChartLoader: false, showRateAppointmentHeatMapChartError: data }
    return nextState
}

const selectedLocationShowRateResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationShowRate: data,
        showRateAppointmentHeatMapChart: {
            ...state.showRateAppointmentHeatMapChart,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }
    }
}
// leads option for ShowRate HeatMapChart 
const leadsOptionShowRateRequestHandler = (state, action) => ({ ...state, showRateAppointmentHeatMapChartLoader: true })
const leadsOptionShowRateResponseHandler = (state, action) => {
    const { data } = action;
    return { ...state, leadsOptionsForShowRateHeatMap: data.data, showRateAppointmentHeatMapChartLoader: false }
}
const leadsOptionShowRateErrorHandler = (state, action) => ({ ...state, showRateAppointmentHeatMapChartLoader: false })

// ! --------------------------------- Funnel Chart ---------------------------------
// Get Insurance Report Funnel Chart API Cal
const insuranceReportFunnelChartAnalyticsRequestHandler = (state, action) => ({ ...state, insuranceReportFunnelChartLoader: true, globalFilterFlag: false })

const insuranceReportFunnelChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const approvedScheduleFirstPyramidFormFills = response;
    const { data } = approvedScheduleFirstPyramidFormFills;
    commonLocalStorageUpdateHandler('localState', 'insuranceReportFunnel', payload)
    return { ...state, insuranceReportFunnelChartLoader: false, insuranceReportFunnelChartResponse: data, insuranceReportFunnel: payload }
}

const insuranceReportFunnelChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, insuranceReportFunnelChartLoader: false, insuranceReportFunnelChartError: data }
}

// leads option for ShowedAppointmentHeatMapChart 
const leadsOptionInsuranceFunnelRequestHandler = (state, action) => ({ ...state, insuranceReportFunnelChartLoader: true })
const leadsOptionInsuranceFunnelResponseHandler = (state, action) => {
    const { data } = action;
    return { ...state, leadsOptionsForInsuranceFunnel: data.data, insuranceReportFunnelChartLoader: false }
}
const leadsOptionInsuranceFunnelErrorHandler = (state, action) => ({ ...state, insuranceReportFunnelChartLoader: false })

// ! --------------------------------- Phone Flow ---------------------------------
// Get SchedulFirst PyramidChart
const phoneFlowSchedulFirstPyramidChartAnalyticsRequestHandler = (state, action) => ({ ...state, phoneFlowSchedulFirstPyramidChartLoader: true, globalFilterFlag: false })

const phoneFlowSchedulFirstPyramidChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const phoneFlowScheduleFirstPyramidFormFills = response;
    const { data } = phoneFlowScheduleFirstPyramidFormFills;
    commonLocalStorageUpdateHandler('localState', 'phoneFlowScheduleFirstPyramid', payload)
    return { ...state, phoneFlowSchedulFirstPyramidChartLoader: false, phoneFlowScheduleFirstPyramidChartResponse: data, phoneFlowScheduleFirstPyramid: payload }
}

const phoneFlowSchedulFirstPyramidChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, phoneFlowSchedulFirstPyramidChartLoader: false, phoneFlowScheduleFirstPyramidChartError: data }
}

// Get InsuranceFirst PyramidChart
const phoneFlowInsuranceFirstPyramidChartAnalyticsRequestHandler = (state, action) => ({ ...state, phoneFlowInsuranceFirstPyramidChartLoader: true, globalFilterFlag: false })

const phoneFlowInsuranceFirstPyramidChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'phoneFlowInsuranceFirstPyramid', payload)
    return { ...state, phoneFlowInsuranceFirstPyramidChartLoader: false, phoneFlowInsuranceFirstPyramidChartResponse: data, phoneFlowInsuranceFirstPyramid: payload }
}

const phoneFlowInsuranceFirstPyramidChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, phoneFlowInsuranceFirstPyramidChartLoader: false, phoneFlowInsuranceFirstPyramidChartError: data }
}

// ! -------------------------------- Google Analytics --------------------------------
const showedAppointmentGoogleAnalyticsChartRequestHandler = (state, action) => ({ ...state, showedAppointmentGoogleAnalyticsChartLoader: true, globalFilterFlag: false })

const showedAppointmentGoogleAnalyticsChartResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'showedAppointmentGoogleAnalytics', payload)
    return { ...state, showedAppointmentGoogleAnalyticsChartLoader: false, showedAppointmentGoogleAnalyticsChartResponse: data, showedAppointmentGoogleAnalytics: payload }
}

const showedAppointmentGoogleAnalyticsChartErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, showedAppointmentGoogleAnalyticsChartLoader: false, showedAppointmentGoogleAnalyticsChartError: data }
}

// ! -------------------------------- TextCenter Id (Practice) --------------------------------

const onChangeTextCenterHandler = (state, action) => {
    const { data } = action;
    commonLocalStorageUpdateHandler('localState', 'textCenterId', data)
    return {
        ...state, textCenterId: data,
        selectedCommonLocation: { label: 'All Location', value: 'All', surgeon: null },
        selectedCommonSurgeon: ["All"],
        selectedCommonWebPages: ['All webpages'],
        selectedCommonLeads: ['All Sources'],
        selectedHeaderProceduresOptions: ["All treatments"],

        commonPayload: {
            ...state.commonPayload,
            location: 'All',
            surgeon: ['All'],
            webPage: ['All webpages'],
            leads: ['All Sources'],
            plans: [{ "label": "All insurance plans", "plan": "All plans", "value": 1000, "is_dynamic": false, "text_center_id": null }],
            procedures: ["All"],
            utm_params: {
                source: ["All"],
                ad_group: ["All"],
                medium: ["All"],
                campaign: ["All"],
                id: ["All"],
                content: ["All"],
                term: ["All"],
            }
        },

        // avaliableAppointmentLineChart: {
        //     ...initialCommonFilter,
        //     surgeon: {
        //         label: "All Providers",
        //         value: "All"
        //     }
        // },
        // approvedScheduleFirstPyramid: {
        //     ...state.approvedScheduleFirstPyramid,
        // },
        // insuranceFirstPyramid: {
        //     ...state.insuranceFirstPyramid,
        // },
        // bookedAppointmentHeatMapChart: {
        //     ...state.bookedAppointmentHeatMapChart,
        // },
        // showedAppointmentHeatMapChart: {
        //     ...state.showedAppointmentHeatMapChart,
        // },
        // showRateAppointmentHeatMapChart: {
        //     ...state.showRateAppointmentHeatMapChart,
        // },
        // clickedSchedulingBarChart: {
        //     ...state.clickedSchedulingBarChart,
        // },
        // filledVsUnfilledAppointment: {
        //     ...state.filledVsUnfilledAppointment,
        // },
        // showedConsultsByLeadSource: {
        //     ...state.showedConsultsByLeadSource,
        // },
        // upcomingConsultsByLeadSource: {
        //     ...state.upcomingConsultsByLeadSource,
        // },
        // showedConsultsPieChart: {
        //     ...state.showedConsultsPieChart,
        // },
        // upcomingConsultsPieChart: {
        //     ...state.upcomingConsultsPieChart,
        // },
        // showedVsNoShowVsCancelledAppointment: {
        //     ...state.showedVsNoShowVsCancelledAppointment,
        // },
        // selectedLocationBooked: { label: 'All Location', value: 'All', surgeon: null },
        // selectedLocationShowed: { label: 'All Location', value: 'All', surgeon: null },
        // selectedLocationShowRate: { label: 'All Location', value: 'All', surgeon: null },
        // selectedLocationClickedBar: { label: 'All Location', value: 'All', surgeon: null },
        // selectedLocationFilledVsUnfilled: { label: 'All Location', value: 'All', surgeon: null },
        // selectedLocationShowedConsultsByLeadSource: { label: 'All Location', value: 'All', surgeon: null },
        // selectedLocationUpcomingConsultsByLeadSource: { label: 'All Location', value: 'All', surgeon: null },
        // selectedLocationShowedConsultsPieChart: { label: 'All Location', value: 'All', surgeon: null },
        // selectedLocationUpcomingConsultsPieChart: { label: 'All Location', value: 'All', surgeon: null },
        // selectedLocationShowedVsNoShowVsCancelled: { label: 'All Location', value: 'All', surgeon: null },

    };
}

// ! --------------------------------- Referral Report ---------------------------------
// Get SchedulFirst PyramidChart
const referralReportSchedulFirstPyramidChartAnalyticsRequestHandler = (state, action) => ({ ...state, referralReportSchedulFirstPyramidChartLoader: true, globalFilterFlag: false })

const referralReportSchedulFirstPyramidChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const referralReportScheduleFirstPyramidFormFills = response;
    const { data } = referralReportScheduleFirstPyramidFormFills;
    commonLocalStorageUpdateHandler('localState', 'referralReportScheduleFirstPyramid', payload)
    return { ...state, referralReportSchedulFirstPyramidChartLoader: false, referralReportScheduleFirstPyramidChartResponse: data, referralReportScheduleFirstPyramid: payload }
}

const referralReportSchedulFirstPyramidChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, referralReportSchedulFirstPyramidChartLoader: false, referralReportScheduleFirstPyramidChartError: data }
}

// Get InsuranceFirst PyramidChart
const referralReportInsuranceFirstPyramidChartAnalyticsRequestHandler = (state, action) => ({ ...state, referralReportInsuranceFirstPyramidChartLoader: true, globalFilterFlag: false })

const referralReportInsuranceFirstPyramidChartAnalyticsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'referralReportInsuranceFirstPyramid', payload)
    return { ...state, referralReportInsuranceFirstPyramidChartLoader: false, referralReportInsuranceFirstPyramidChartResponse: data, referralReportInsuranceFirstPyramid: payload }
}

const referralReportInsuranceFirstPyramidChartAnalyticsErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, referralReportInsuranceFirstPyramidChartLoader: false, referralReportInsuranceFirstPyramidChartError: data }
}

// ! --------------------------------- Bar Chart ---------------------------------
// Get InsuranceFirst PyramidChart
const clickedSchedulingBarChartRequestHandler = (state, action) => ({ ...state, clickedSchedulingBarChartLoader: true, globalFilterFlag: false })

const clickedSchedulingBarChartResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'clickedSchedulingBarChart', payload)
    return { ...state, clickedSchedulingBarChartLoader: false, clickedSchedulingBarChartResponse: data, clickedSchedulingBarChart: payload }
}

const clickedSchedulingBarChartErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, clickedSchedulingBarChartLoader: false, clickedSchedulingBarChartError: data }
}

const selectedLocationClickedBarResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationClickedBar: data,
        clickedSchedulingBarChart: {
            ...state.clickedSchedulingBarChart,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }

    }
}

// ! --------------------------------- Stacked Column 100% Chart ---------------------------------
// Get InsuranceFirst PyramidChart
const filledVsUnfilledAppointmentRequestHandler = (state, action) => ({ ...state, filledVsUnfilledAppointmentLoader: true, globalFilterFlag: false })

const filledVsUnfilledAppointmentResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'filledVsUnfilledAppointment', payload)
    return { ...state, filledVsUnfilledAppointmentLoader: false, filledVsUnfilledAppointmentResponse: data, filledVsUnfilledAppointment: payload }
}

const filledVsUnfilledAppointmentErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, filledVsUnfilledAppointmentLoader: false, filledVsUnfilledAppointmentError: data }
}

const selectedLocationFilledVsUnfilledResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationFilledVsUnfilled: data,
        filledVsUnfilledAppointment: {
            ...state.filledVsUnfilledAppointment,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }
    }
}

// ! --------------------------------- Stacked Column Chart ---------------------------------
// Showed Consults By Lead Source
const showedConsultsByLeadSourceRequestHandler = (state, action) => ({ ...state, showedConsultsByLeadSourceLoader: true, globalFilterFlag: false })

const showedConsultsByLeadSourceResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'showedConsultsByLeadSource', payload)

    //! Make this Code Change Name Like Google AdWords To Google Ads In Key And Value Both Start here
    // Loop through the data array
    data.data.forEach(entry => {
        // Loop through the keys of each entry
        Object.keys(entry).forEach(key => {
            // Check if the key contains "Google AdWords"
            if (key.includes("Google AdWords")) {
                // Replace "Google AdWords" with "Google Ads"
                const updatedKey = key.replace("Google AdWords", "Google Ads");
                // Assign the value of the current key to the updated key
                entry[updatedKey] = entry[key];
                // Delete the old key
                delete entry[key];
            }
        });
    });
    // Loop through the seriesArray
    data.seriesArray.forEach(series => {
        // Check if the series name contains "Google AdWords"
        if (series.name.includes("Google AdWords")) {
            // Replace "Google AdWords" with "Google Ads"
            series.name = series.name.replace("Google AdWords", "Google Ads");
            series.legendName = series.legendName.replace("Google AdWords", "Google Ads");
            series.tooltipName = series.tooltipName.replace("Google AdWords", "Google Ads");
        }
    });
    // After these changes, all occurrences of "Google AdWords" in both data and seriesArray are changed to "Google Ads"
    //! End here changes Code 

    return { ...state, showedConsultsByLeadSourceLoader: false, showedConsultsByLeadSourceResponse: data, showedConsultsByLeadSource: payload }
}

const showedConsultsByLeadSourceErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, showedConsultsByLeadSourceLoader: false, showedConsultsByLeadSourceError: data }
}

const selectedLocationShowedConsultsByLeadSourceResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationShowedConsultsByLeadSource: data,
        showedConsultsByLeadSource: {
            ...state.showedConsultsByLeadSource,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }
    }
}

// Upcoming Consults By Lead Source
const upcomingConsultsByLeadSourceRequestHandler = (state, action) => ({ ...state, upcomingConsultsByLeadSourceLoader: true, globalFilterFlag: false })

const upcomingConsultsByLeadSourceResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;

    //! Make this Code Change Name Like Google AdWords To Google Ads In Key And Value Both Start here
    // Loop through the data array
    data.data.forEach(entry => {
        // Loop through the keys of each entry
        Object.keys(entry).forEach(key => {
            // Check if the key contains "Google AdWords"
            if (key.includes("Google AdWords")) {
                // Replace "Google AdWords" with "Google Ads"
                const updatedKey = key.replace("Google AdWords", "Google Ads");
                // Assign the value of the current key to the updated key
                entry[updatedKey] = entry[key];
                // Delete the old key
                delete entry[key];
            }
        });
    });
    // Loop through the seriesArray
    data.seriesArray.forEach(series => {
        // Check if the series name contains "Google AdWords"
        if (series.name.includes("Google AdWords")) {
            // Replace "Google AdWords" with "Google Ads"
            series.name = series.name.replace("Google AdWords", "Google Ads");
            series.legendName = series.legendName.replace("Google AdWords", "Google Ads");
            series.tooltipName = series.tooltipName.replace("Google AdWords", "Google Ads");
        }
    });
    // After these changes, all occurrences of "Google AdWords" in both data and seriesArray are changed to "Google Ads"
    //! End here changes Code 

    commonLocalStorageUpdateHandler('localState', 'upcomingConsultsByLeadSource', payload)
    return { ...state, upcomingConsultsByLeadSourceLoader: false, upcomingConsultsByLeadSourceResponse: data, upcomingConsultsByLeadSource: payload }
}

const upcomingConsultsByLeadSourceErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, upcomingConsultsByLeadSourceLoader: false, upcomingConsultsByLeadSourceError: data }
}

const selectedLocationUpcomingConsultsByLeadSourceResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationUpcomingConsultsByLeadSource: data,
        upcomingConsultsByLeadSource: {
            ...state.upcomingConsultsByLeadSource,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }
    }
}

// Get showed Vs NoShow Vs Cancelled Appointment
const showedVsNoShowVsCancelledAppointmentRequestHandler = (state, action) => ({ ...state, showedVsNoShowVsCancelledAppointmentLoader: true, globalFilterFlag: false })

const showedVsNoShowVsCancelledAppointmentResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'showedVsNoShowVsCancelledAppointment', payload)
    return { ...state, showedVsNoShowVsCancelledAppointmentLoader: false, showedVsNoShowVsCancelledAppointmentResponse: data, showedVsNoShowVsCancelledAppointment: payload }
}

const showedVsNoShowVsCancelledAppointmentErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, showedVsNoShowVsCancelledAppointmentLoader: false, showedVsNoShowVsCancelledAppointmentError: data }
}

const selectedLocationShowedVsNoShowVsCancelledResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationShowedVsNoShowVsCancelled: data,
        showedVsNoShowVsCancelledAppointment: {
            ...state.showedVsNoShowVsCancelledAppointment,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }
    }
}

// ! --------------------------------- Pie Chart ---------------------------------
// Showed Consults
const getShowedConsultsPieChartRequestHandler = (state, action) => ({ ...state, showedConsultsPieChartLoader: true, globalFilterFlag: false })

const getShowedConsultsPieChartResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'showedConsultsPieChart', payload)
    return { ...state, showedConsultsPieChartLoader: false, showedConsultsPieChartResponse: data, showedConsultsPieChart: payload }
}

const getShowedConsultsPieChartErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, showedConsultsPieChartLoader: false, showedConsultsPieChartError: data }
}

//filled_vs_unfilled_future_appointment_slots_PieChart
const getFilledVsUnfilledFutureAppointmentSlotsPieCharRequestHandler = (state, action) => ({ ...state, FilledVsUnfilledFutureAppointmentSlotsPieChartLoader: true, globalFilterFlag: false })

const getFilledVsUnfilledFutureAppointmentSlotsPieCharResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'showedConsultsPieChart', payload)
    return { ...state, FilledVsUnfilledFutureAppointmentSlotsPieChartLoader: false, FilledVsUnfilledFutureAppointmentSlotsPieChartResponse: data, showedConsultsPieChart: payload }
}

const getFilledVsUnfilledFutureAppointmentSlotsPieCharErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, FilledVsUnfilledFutureAppointmentSlotsPieChartLoader: false, showedConsultsPieChartError: data }
}


const selectedLocationShowedConsultsPieChartResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationShowedConsultsPieChart: data,
        showedConsultsPieChart: {
            ...state.showedConsultsPieChart,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }
    }
}


// ! ----------------------------------------------------------------Click to 1 ---------------------------------
const getClickToCallScheduledShowedConsultsRequestHandler = (state, action) => ({ ...state, ClickToCallScheduledShowedConsultsChartLoader: true, globalFilterFlag: false })

const getClickToCallScheduledShowedConsultsResponseHandler = (state, action) => {
    const { response } = action;
    const { data } = response;
    return { ...state, ClickToCallScheduledShowedConsultsChartLoader: false, ClickToCallScheduledShowedConsultsResponse: data }
}
const getClickToCallScheduledShowedConsultsHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, ClickToCallScheduledShowedConsultsChartLoader: false, ClickToCallScheduledShowedConsultsError: data }
};

// !----------------------------------------------------------------- 2 Click To ---------------------------------
const getClicktocallscheduledshowedconsultsbyleadsourceRequestHandler = (state, action) => ({ ...state, ClicktocallscheduledshowedconsultsbyleadsourceLoder: true, globalFilterFlag: false })
const getClicktocallscheduledshowedconsultsbyleadsourceResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    // Loop through the data
    data.data.forEach(entry => {
        // Loop through the keys of each entry
        Object.keys(entry).forEach(key => {
            // Check if the key contains "Google AdWords"
            if (key.includes("Google AdWords")) {
                // Replace "Google AdWords" with "Google Ads"
                const updatedKey = key.replace("Google AdWords", "Google Ads");
                // Assign the value of the current key to the updated key
                entry[updatedKey] = entry[key];
                // Delete the old key
                delete entry[key];
            }

            // Check if the value contains "Google AdWords"
            if (typeof entry[key] === 'string' && entry[key].includes("Google AdWords")) {
                // Replace "Google AdWords" with "Google Ads"
                entry[key] = entry[key].replace("Google AdWords", "Google Ads");
            }
        });
    });

    // Loop through the seriesArray
    data.seriesArray.forEach(series => {
        // Check if the series name contains "Google AdWords"
        if (series.name.includes("Google AdWords")) {
            // Replace "Google AdWords" with "Google Ads"
            series.name = series.name.replace("Google AdWords", "Google Ads");
            series.legendName = series.legendName.replace("Google AdWords", "Google Ads");
            series.tooltipName = series.tooltipName.replace("Google AdWords", "Google Ads");
        }
    });
    commonLocalStorageUpdateHandler('localState', 'upcomingConsultsPieChart', payload)
    return { ...state, ClicktocallscheduledshowedconsultsbyleadsourceLoder: false, ClicktocallscheduledshowedconsultsbyleadsourceResponse: data, upcomingConsultsPieChart: payload }
}
const getClicktocallscheduledshowedconsultsbyleadsourceErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, ClicktocallscheduledshowedconsultsbyleadsourceLoder: false, ClicktocallscheduledshowedconsultsbyleadsourceError: data }
}

// !------------------------------------------------------------------ 3 Click To ---------------------------------
const getClicktocallscheduledshowedconsultsformsubmissiondateRequestHandler = (state, action) => {
    return ({ ...state, ClicktocallscheduledshowedconsultsformsubmissiondateLoder: true, globalFilterFlag: false })
}
const getClicktocallscheduledshowedconsultsformsubmissiondateResponseHandler = (state, action) => {
    const { response, payload } = action;
    const showedAndUpcomingConsultReport = response;
    const { data } = showedAndUpcomingConsultReport;

    // Loop through the data
    data.data.forEach(entry => {
        // Loop through the keys of each entry
        Object.keys(entry).forEach(key => {
            // Check if the key contains "Google AdWords"
            if (key.includes("Google AdWords")) {
                // Replace "Google AdWords" with "Google Ads"
                const updatedKey = key.replace("Google AdWords", "Google Ads");
                // Assign the value of the current key to the updated key
                entry[updatedKey] = entry[key];
                // Delete the old key
                delete entry[key];
            }
        });
    });

    // Loop through the seriesArray
    data.seriesArray.forEach(series => {
        // Check if the title contains "Google AdWords"
        if (series.title.includes("Google AdWords")) {
            // Replace "Google AdWords" with "Google Ads"
            series.title = series.title.replace("Google AdWords", "Google Ads");
        }
    });



    const nextState = { ...state, ClicktocallscheduledshowedconsultsformsubmissiondateLoder: false, ClicktocallscheduledshowedconsultsformsubmissiondateResponse: data, showedAndUpcomingConsultReport: payload };
    commonLocalStorageUpdateHandler('localState', 'showedAndUpcomingConsultReport', payload)
    return nextState
}
const getClicktocallscheduledshowedconsultsformsubmissiondateErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    return { ...state, ClicktocallscheduledshowedconsultsformsubmissiondateLoder: false, ClicktocallscheduledshowedconsultsformsubmissiondateError: data }
}

// !------------------------------------------------------------------ 4 Click To ---------------------------------
const getClicktocallfunnelRequestHandler = (state, action) => {
    return ({ ...state, ClicktocallfunnelLoader: true, globalFilterFlag: false })
}
const getClicktocallfunnelResponseHandler = (state, action) => {
    const { response, payload } = action;
    const approvedScheduleFirstPyramidFormFills = response;
    const { data } = approvedScheduleFirstPyramidFormFills;
    commonLocalStorageUpdateHandler('localState', 'insuranceFirstPyramid', payload)
    return { ...state, ClicktocallfunnelLoader: false, ClicktocallfunnelResponse: data, insuranceFirstPyramid: payload }
}
const getClicktocallfunnelErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, ClicktocallfunnelLoader: false, ClicktocallfunnelError: data }
}


// !------------------------------------------------------------------ 5 Click To ---------------------------------

const getClicktocallwebsiteConversionRequestHandler = (state, action) => {

    return { ...state, ClicktocallwebsiteConversionLoader: true, globalFilterFlag: false }
};

const getClicktocallwebsiteConversionResponseHandler = (state, action) => {
    const { response, payload } = action;
    const websiteConversion = response;
    const { data } = websiteConversion;
    const nextState = { ...state, ClicktocallwebsiteConversionLoader: false, ClicktocallwebsiteConversionResponse: data, websiteConversion: payload };
    commonLocalStorageUpdateHandler('localState', 'websiteConversion', payload)
    return nextState
}

const getClicktocallwebsiteConversionErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    const temp = [...state.websiteConversionResponse];
    return { ...state, ClicktocallwebsiteConversionLoader: false, ClicktocallwebsiteConversionError: data, websiteConversionResponse: { ...temp, isForm: data.isForm } }
}

// ! -------------------------------- open_action_item_average_turn_around_time --------------------------------

const getOpenActionItemAverageTurnAroundTimeRequestHandler = (state, action) => {
    return ({ ...state, OpenActionItemAverageTurnAroundTimeRequesLoader: true, globalFilterFlag: false })
}
const getOpenActionItemAverageTurnAroundTimeResponseHandler = (state, action) => {
    const { response } = action;
    const { data } = response;
    return { ...state, OpenActionItemAverageTurnAroundTimeRequesLoader: false, OpenActionItemAverageTurnAroundTimeResponse: data }
}
const getOpenActionItemAverageTurnAroundTimeErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, OpenActionItemAverageTurnAroundTimeRequesLoader: false, OpenActionItemAverageTurnAroundTimeError: data }
}

// ! -------------------------------- open_action_items_resolved_by_user --------------------------------

const getOpenActionItemsResolvedByUserRequestHandler = (state, action) => {
    return ({ ...state, openActionItemsResolvedByUserLoader: true, globalFilterFlag: false })
}

const getOpenActionItemsResolvedByUserResponseHandler = (state, action) => {
    const { response } = action;
    const { data } = response;
    return { ...state, openActionItemsResolvedByUserLoader: false, OpenActionItemsResolvedByUserResponse: data }
}

const getOpenActionItemsResolvedByUserErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, openActionItemsResolvedByUserLoader: false, OpenActionItemsResolvedByUserError: data }
}


// ! -------------------------------- FOLLOW_UP_IMPACT_REPORT_CHART -------------------------------
const getFollowUpImpactReportChartRequestHandler = (state, action) => {
    return { ...state, FollowUpImpactReportChartLoader: true, globalFilterFlag: false }
}
const getFollowUpImpactReportChartResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    // commonLocalStorageUpdateHandler('localState', 'insuranceFirstPyramid', payload)
    return { ...state, FollowUpImpactReportChartLoader: false, FollowUpImpactReportChartResponse: data }
}

const getFollowUpImpactReportChartErrorHandler = (state, action) => {
    const { error } = action;
    // const { data } = error.response;
    return { ...state, FollowUpImpactReportChartLoader: false, FollowUpImpactReportChartError: error }
}

// ! --------------------------------- Pie Chart ---------------------------------

// Upcoming Consults
const getUpcomingConsultsPieChartRequestHandler = (state, action) => ({ ...state, upcomingConsultsPieChartLoader: true, globalFilterFlag: false })

const getUpcomingConsultsPieChartResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'upcomingConsultsPieChart', payload)
    return { ...state, upcomingConsultsPieChartLoader: false, upcomingConsultsPieChartResponse: data, upcomingConsultsPieChart: payload }
}

const getUpcomingConsultsPieChartErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, upcomingConsultsPieChartLoader: false, upcomingConsultsPieChartError: data }
}

const selectedLocationUpcomingConsultsPieChartResponseHandler = (state, action) => {
    const { data } = action;
    return {
        ...state, selectedLocationUpcomingConsultsPieChart: data,
        upcomingConsultsPieChart: {
            ...state.upcomingConsultsPieChart,
            surgeon: {
                label: "All Providers",
                value: "All"
            }
        }
    }
}

// ! -------------------------------- facebook_ads_cost_per_conversion ------------------------------
const getGetFacbookAdsCostPerConversionRequestHandler = (state, action) => ({ ...state, FacebookAdsCostPerConversionLoader: true, globalFilterFlag: false });

const getGetFacbookAdsCostPerConversionResponseHandler = (state, action) => {
    const { response, payload } = action;
    const nextState = {
        ...state, FacebookAdsCostPerConversionLoader: false, FacebookAdsCostPerConversionResponse: response.resObj, facebookConversion: payload, facebookAppId: response.resObj.customerId
    };
    // commonLocalStorageUpdateHandler('localState', 'FacebookAdsCostPerConversion', payload)
    return nextState
}

const getGetFacbookAdsCostPerConversionErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    const temp = [...state.websiteConversionResponse];
    return { ...state, FacebookAdsCostPerConversionLoader: false, FacebookAdsCostPerConversionError: data, FacebookAdsCostPerConversionResponse: { ...temp, isForm: data?.isForm } }
}

// ! -------------------------------- facebook_ads_cost_per_conversion_funnel ------------------------------=
const getFacebookAdsCostPerConversionFunnelRequestHandler = (state, action) => ({ ...state, facebookAdsCostPerConversionFunnelLoader: true })

const getFacebookAdsCostPerConversionFunnelResponseHandler = (state, action) => {
    const { response, payload } = action;
    return { ...state, facebookAdsCostPerConversionFunnelLoader: false, facebookAdsCostPerConversionFunnelResponse: response.resObj }
}

const getFacebookAdsCostPerConversionFunnelErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, facebookAdsCostPerConversionFunnelLoader: false, facebookAdsCostPerConversionFunnelError: data }
}

// ! -------------------------------- google_ads_cost_per_conversion ------------------------------
const getGetGoogleAdsCostPerConversionRequestHandler = (state, action) => ({ ...state, GoogleAdsCostPerConversionLoader: true, globalFilterFlag: false });

const getGetGoogleAdsCostPerConversionResponseHandler = (state, action) => {
    const { response, payload } = action;
    const websiteConversion = response;
    const { data } = websiteConversion;
    const nextState = { ...state, GoogleAdsCostPerConversionLoader: false, GoogleAdsCostPerConversionResponse: data, GoogleClientId: data?.clientId, GooglecustomerIdForHeader: data?.customerIdForHeader, websiteConversion: payload };
    // commonLocalStorageUpdateHandler('localState', 'FacebookAdsCostPerConversion', payload)
    return nextState
}

const getGetGoogleAdsCostPerConversionErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error;
    const temp = [...state.websiteConversionResponse];
    return { ...state, GoogleAdsCostPerConversionLoader: false, GoogleAdsCostPerConversionError: data, FacebookAdsCostPerConversionResponse: { ...temp, isForm: data.isForm } }
}



// ! -------------------------------- google_ads_cost_per_conversion_funnel ------------------------------=
const getgoogleAdsCostPerConversionFunnelRequestHandler = (state, action) => ({ ...state, googleAdsCostPerConversionFunnelLoader: true })

const getgoogleAdsCostPerConversionFunnelResponseHandler = (state, action) => {
    const { response, payload } = action;
    const approvedScheduleFirstPyramidFormFills = response;
    const { data } = approvedScheduleFirstPyramidFormFills;
    // commonLocalStorageUpdateHandler('localState', 'insuranceReportFunnel', payload)
    return { ...state, googleAdsCostPerConversionFunnelLoader: false, googleAdsCostPerConversionFunnelResponse: data, }
}

const getgoogleAdsCostPerConversionFunnelErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, googleAdsCostPerConversionFunnelLoader: false, GoogleAdsCostPerConversionFunnelError: data }
}

// ! -------------------------------- Top_Performing_Ads --------------------------------------------------
const getTopPerformingAdsRequestHandler = (state, action) => ({ ...state, TopPerformingAdsLoader: true, globalFilterFlag: false });

const getTopPerformingAdsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const nextState = { ...state, TopPerformingAdsLoader: false, TopPerformingAdsResponse: response };
    // commonLocalStorageUpdateHandler('localState', 'TopPerformingReport', payload)
    return nextState
}
const getTopPerFormingAdsSelectedGoogleSourceOptionsHandler = (state, action) => {
    const { FiltterSlectedValue } = action;
    const nextState = { ...state, TopPerFormingSelectedGoogleSourceOptions: FiltterSlectedValue };
    commonLocalStorageUpdateHandler('localState', 'TopPerFormingSelectedGoogleSourceOptions', FiltterSlectedValue)
    return nextState
}

const getTopPerFormingAdsSelectedFacebookeSourceOptionsHandler = (state, action) => {
    const { FiltterSlectedValue } = action;

    const nextState = { ...state, TopPerFormingSelectedFacebookSourceOptions: FiltterSlectedValue };
    commonLocalStorageUpdateHandler('localState', 'TopPerFormingSelectedFacebookSourceOptions', FiltterSlectedValue)
    return nextState
}
// const getTopPerFormingAdsSelectedInstagramSourceOptionsHandler = (state, action) => {
//     const { FiltterSlectedValue } = action;

//     const nextState = { ...state, TopPerFormingSelectedInstagramSourceOptions: FiltterSlectedValue };
//     commonLocalStorageUpdateHandler('localState', 'TopPerFormingSelectedInstagramSourceOptions', FiltterSlectedValue)
//     return nextState
// }
const getTopPerFormingAdsSelectedGropOptionsHandler = (state, action) => {
    const { FiltterSlectedValue } = action;
    const nextState = { ...state, TopPerFormingSelectedGropsOptions: FiltterSlectedValue };
    // commonLocalStorageUpdateHandler('localState', 'TopPerFormingSelectedGropsOptions', FiltterSlectedValue)
    return nextState
}

// ! -------------------------------- Non_Converting_Ads --------------------------------------------------
const getNonConvertingAdsRequestHandler = (state, action) => ({ ...state, NonConvertingAdsLoader: true, globalFilterFlag: false });

const getNonConvertingAdsResponseHandler = (state, action) => {
    const { response, payload } = action;
    const nextState = { ...state, NonConvertingAdsLoader: false, NonConvertingAdsResponse: response };
    // commonLocalStorageUpdateHandler('localState', 'TopPerformingReport', payload)
    return nextState
}

const getNonConvertingAdsSelectedGoogleSourceOptionsHandler = (state, action) => {
    const { FiltterSlectedValue } = action;
    const nextState = { ...state, NonConvertinSelectedGoogleSourceOptions: FiltterSlectedValue };
    commonLocalStorageUpdateHandler('localState', 'NonConvertinSelectedGoogleSourceOptions', FiltterSlectedValue)
    return nextState
}
const getNonConvertingAdsSelectedFacebookeSourceOptionsHandler = (state, action) => {
    const { FiltterSlectedValue } = action;

    const nextState = { ...state, NonConvertinSelectedFacebookSourceOptions: FiltterSlectedValue };
    commonLocalStorageUpdateHandler('localState', 'NonConvertinSelectedFacebookSourceOptions', FiltterSlectedValue)
    return nextState
}
// const getNonConvertingAdsSelectedInstagramSourceOptionsHandler = (state, action) => {
//     const { FiltterSlectedValue } = action;
//     console.log(FiltterSlectedValue, "FiltterSlectedValue---------->");
//     const nextState = { ...state, NonConvertinSelectedInstagramSourceOptions: FiltterSlectedValue };
//     commonLocalStorageUpdateHandler('localState', 'NonConvertinSelectedInstagramSourceOptions', FiltterSlectedValue)
//     return nextState
// }
const getNonConvertingAdsSelectedGropOptionsHandler = (state, action) => {
    const { FiltterSlectedValue } = action;
    const nextState = { ...state, NonConvertinSelectedGropsOptions: FiltterSlectedValue };
    // commonLocalStorageUpdateHandler('localState', 'TopPerFormingSelectedGropsOptions', FiltterSlectedValue)
    return nextState
}

// ! -------------------------------- Google_Ads_Form_Submission_Date --------------------------------------------------

const GetGoogleAdsAuthDateHandler = (state, action) => {
    const { FiltterSlectedValue } = action;
    const nextState = { ...state, GoogleAuthDateReng: FiltterSlectedValue };
    // commonLocalStorageUpdateHandler('localState', 'TopPerFormingSelectedGropsOptions', FiltterSlectedValue)
    return nextState
}
// ! -------------------------------- Meta_Ads_Form_Submission_Date --------------------------------------------------

const GetMetaAdsAuthDateHandler = (state, action) => {
    const { FiltterSlectedValue } = action;
    const nextState = { ...state, MetaAuthDateReng: FiltterSlectedValue };
    // commonLocalStorageUpdateHandler('localState', 'TopPerFormingSelectedGropsOptions', FiltterSlectedValue)
    return nextState
}
const GetFacebookSearchBoxHandler = (state, action) => {
    const { FacebookSearchBoxValue } = action;
    const nextState = { ...state, FacebookSearchValue: FacebookSearchBoxValue };
    // commonLocalStorageUpdateHandler('localState', 'TopPerFormingSelectedGropsOptions', FiltterSlectedValue)
    return nextState
}

//! -------------------------------- Conversion_Funnel_By_Insurance_company ------------------------------

const GetConversionFunnelByInsuranceCompanyRequestHandler = (state, action) => ({ ...state, ConversionFunnelByInsuranceCompanyLoader: true, globalFilterFlag: false })

const GetConversionFunnelByInsuranceCompanyResponseHandler = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'ConversionFunnelByInsuranceCompany', payload)
    return { ...state, ConversionFunnelByInsuranceCompanyLoader: false, ConversionFunnelByInsuranceCompanyResponse: data, }
}
const GetConversionFunnelByInsuranceCompanyErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, ConversionFunnelByInsuranceCompanyLoader: false, ConversionFunnelByInsuranceCompanyError: data }
}

//! -------------------------------- Schedule_First_Funnel_Report_Over_Time ------------------------------

const GetScheduleFirstFunnelReportOverTimeRequestHandler = (state, action) => ({ ...state, ScheduleFirstFunnelReportOverTimeLoader: true, globalFilterFlag: false })

const getScheduleFirstFunnelReportOverTimeResponse = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'ScheduleFirstFunnelReportOverTime', payload)
    return { ...state, ScheduleFirstFunnelReportOverTimeLoader: false, ScheduleFirstFunnelReportOverTimeResponse: data, }
}

const GetScheduleFirstFunnelReportOverTimeErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, ScheduleFirstFunnelReportOverTimeLoader: false, ScheduleFirstFunnelReportOverTimeError: data }
}

//!-------------------------------Insurance_First_Funnel_Report_Over_Time --------------------------------

const GetInsuranceFirstFunnelReportOverTimeRequestHandler = (state, action) => ({ ...state, InsuranceFirstFunnelReportOverTimeLoader: true, globalFilterFlag: false })

const getInsuranceFirstFunnelReportOverTimeResponse = (state, action) => {
    const { response, payload } = action;
    const { data } = response;
    commonLocalStorageUpdateHandler('localState', 'InsuranceFirstFunnelReportOverTime', payload)
    return { ...state, InsuranceFirstFunnelReportOverTimeLoader: false, InsuranceFirstFunnelReportOverTimeResponse: data, }
}

const GetInsuranceFirstFunnelReportOverTimeErrorHandler = (state, action) => {
    const { error } = action;
    const { data } = error.response;
    return { ...state, InsuranceFirstFunnelReportOverTimeLoader: false, InsuranceFirstFunnelReportOverTimeError: data }
}

// ------------------------------------------------------------------------------------------------
const commonOnChangeHandler = (state, action) => {

    // console.log('--------------commonOnChangeHandler');


    let nextState = { ...state };
    const { syntheticEvent } = action;
    const { key, nextStartDate, nextEndDate, selectedOption, selectedProceduresOptions, selectedLeads, selectedLocation, selectedSurgeon, selectedWebPages, selectedPlans, utm_params, TopPerFormingSelectedGoogleSourceOptions, TopPerFormingSelectedFacebookSourceOptions, TopPerFormingSelectedInstagramSourceOptions, NonConvertinSelectedFacebookSourceOptions, NonConvertinSelectedInstagramSourceOptions, NonConvertinSelectedGoogleSourceOptions } = syntheticEvent;

    const calenderFilter = { startDate: nextStartDate, endDate: nextEndDate };
    switch (key) {
        case 'globalFilter':
            const nextFilterState = {
                [key]: calenderFilter,
                approvedFormFills: { ...state.approvedFormFills, ...calenderFilter },
                showedAndUpcomingConsultReport: calenderFilter,
                showedAndUpcomingConsultAppointment: calenderFilter,
                avaliableAppointmentLineChart: { ...state.avaliableAppointmentLineChart, ...calenderFilter },
                websiteConversion: { ...state.websiteConversion, ...calenderFilter },
                approvedScheduleFirstPyramid: { ...state.approvedScheduleFirstPyramid, ...calenderFilter },
                insuranceFirstPyramid: { ...state.insuranceFirstPyramid, ...calenderFilter },
                gaugeChart: { ...state.gaugeChart, ...calenderFilter }, bookedAppointmentHeatMapChart: { ...state.bookedAppointmentHeatMapChart, ...calenderFilter },
                showedAppointmentHeatMapChart: { ...state.showedAppointmentHeatMapChart, ...calenderFilter },
                showRateAppointmentHeatMapChart: { ...state.showRateAppointmentHeatMapChart, ...calenderFilter },
                filledVsUnfilledAppointment: { ...state.filledVsUnfilledAppointment, ...calenderFilter },
                showedConsultsByLeadSource: { ...state.showedConsultsByLeadSource, ...calenderFilter },
                upcomingConsultsByLeadSource: { ...state.upcomingConsultsByLeadSource, ...calenderFilter },
                showedVsNoShowVsCancelledAppointment: { ...state.showedVsNoShowVsCancelledAppointment, ...calenderFilter },
                clickedSchedulingBarChart: { ...state.clickedSchedulingBarChart, ...calenderFilter },
                insuranceReportFunnel: { ...state.insuranceReportFunnel, ...calenderFilter },
                showedConsultsPieChart: { ...state.showedConsultsPieChart, ...calenderFilter },
                upcomingConsultsPieChart: { ...state.upcomingConsultsPieChart, ...calenderFilter },
                phoneFlowScheduleFirstPyramid: calenderFilter,
                phoneFlowInsuranceFirstPyramid: calenderFilter,
                showedAppointmentGoogleAnalytics: calenderFilter,
                referralReportScheduleFirstPyramid: calenderFilter,
                referralReportInsuranceFirstPyramid: calenderFilter,
                selectedTimeFrameOption: selectedOption,
                selectedHeaderProceduresOptions: selectedProceduresOptions,
                selectedCommonLocation: selectedLocation,
                selectedCommonSurgeon: selectedSurgeon,
                alertConversion: calenderFilter,
                facebookConversion: { ...state.facebookConversion, ...calenderFilter },

                commonPayload: {
                    ...state.commonPayload,
                    leads: selectedLeads,
                    webPage: selectedWebPages,
                    option: selectedOption.value,
                    location: selectedLocation.value,
                    surgeon: selectedSurgeon,
                    procedures: selectedProceduresOptions,
                    plans: selectedPlans,
                    utm_params: {
                        source: ["All"],
                        ad_group: ["All"],
                        medium: ["All"],
                        campaign: ["All"],
                        id: ["All"],
                        content: ["All"],
                        term: ["All"],
                    },
                    TopPerFormingSelectedGoogleSourceOptions: TopPerFormingSelectedGoogleSourceOptions,
                    TopPerFormingSelectedFacebookSourceOptions: TopPerFormingSelectedFacebookSourceOptions,
                    TopPerFormingSelectedInstagramSourceOptions: TopPerFormingSelectedInstagramSourceOptions,
                    NonConvertinSelectedGoogleSourceOptions: NonConvertinSelectedGoogleSourceOptions,
                    NonConvertinSelectedFacebookSourceOptions: NonConvertinSelectedFacebookSourceOptions,
                    NonConvertinSelectedInstagramSourceOptions: NonConvertinSelectedInstagramSourceOptions

                },
            }


            let localState = localStorageDecryption('localState');
            localState = { ...localState, ...nextFilterState };
            localStorageEncryption('localState', localState);

            nextState = {
                ...nextState, ...nextFilterState, selectedCommonLeads: selectedLeads, selectedCommonWebPages: selectedWebPages, commonPayload: { ...nextFilterState.commonPayload, utm_params },
                //  commonPayload: { ...nextFilterState.commonPayload, TopPerFormingSelectedGoogleSourceOptions },
                //   commonPayload: { ...nextFilterState.commonPayload, TopPerFormingSelectedFacebookSourceOptions },
                //    commonPayload: { ...nextFilterState.commonPayload, NonConvertinSelectedGoogleSourceOptions },
                //     commonPayload: { ...nextFilterState.commonPayload, NonConvertinSelectedFacebookSourceOptions } 
            }
            return nextState;

        default: break;
    }
}

const changeCurrentPageHandler = (state, action) => {
    const { data } = action;
    let nextTimeFrame = state.selectedTimeFrameOption

    if (data.name === 'Alerts') {
        nextTimeFrame = { label: 'Day', value: 'Date' }
    }
    return { ...state, currentPage: data.page, currentPageName: data.name, selectedTimeFrameOption: nextTimeFrame, commonPayload: { ...state.commonPayload, option: nextTimeFrame.value } }
}


// * Insurance Reducer ******************* Insurance Reducer ******************* Insurance Reducer ******************* Insurance Reducer

const filterState = localStorageDecryption('localState');

const tempState = filterState ? { ...initialState, ...filterState } : initialState

const analyticsReducer = (state = tempState, action) => {

    switch (action.type) {

        case actionTypes.TOGGLE_SIDEBAR_MENU: return toggleSidebarHandler(state, action); // Toggle sidebar

        case actionTypes.TOGGLE_CTC_FULL_HIVE_HENDER_BTN: return CTCFullHiveToggleHandlerBtn(state, action) // Toggle For Click To call Practice User 48 Only 
        case actionTypes.TOGGLE_CTC_SCHEDULING_ONLY_BTN: return CTCSchedulingOnlyToggleHandlerBtn(state, action) // Toggle For Click To call Practice User 48 Only 
        case actionTypes.TOGGLE_CTC_CLICK_TO_CALL_BTN: return CTCSClickToCallToggleHandlerBtn(state, action) // Toggle For Click To call Practice User 48 Only 
        case actionTypes.CTT_SELECTED_TOGGLE_PAYLOAD: return CTCSelectedTogglePayloadHandler(state, action)  //Click To Call This Three Toggle Payload

        case actionTypes.BUSINESS_HOURS_BTN: return businesshoursBtnHandler(state, action)

        case actionTypes.TOGGLE_HEADER_BTN: return toggleHeaderBtnHandler(state, action); // Toggle Header btn

        case actionTypes.TOGGLE_TOP_HEADER: return toggleTopHeaderHandler(state, action); // Toggle sidebar

        case actionTypes.DOTS_OPTION: return toggleDotsHeaderHandler(state, action); // Toggle sidebar

        // Authentication
        case actionTypes.AUTHENTICATION_REQUEST: return authenticationRequestHandler(state, action);
        case actionTypes.AUTHENTICATION_RESPONSE: return authenticationdResponseHandler(state, action);
        case actionTypes.AUTHENTICATION_ERROR: return authenticationErrorHandler(state, action);

        case actionTypes.GET_BSS_USERS_VIEW_RESPONSE: return getBssUsersViewResponseHandler(state, action);

        // Get text center id
        case actionTypes.GET_TEXT_CENTER_REQUEST: return textCenterIdRequestHandler(state, action);
        case actionTypes.GET_TEXT_CENTER_RESPONSE: return textCenterIdResponseHandler(state, action);
        case actionTypes.GET_TEXT_CENTER_ERROR: return textCenterIdErrorHandler(state, action);

        // Get Leads Option Array
        case actionTypes.GET_COMMON_LEADS_OPTION_REQUEST: return leadsCommonOptionRequestHandler(state, action);
        case actionTypes.GET_COMMON_LEADS_OPTION_RESPONSE: return leadsCommonOptionResponseHandler(state, action);
        case actionTypes.GET_COMMON_LEADS_OPTION_ERROR: return leadsCommonOptionErrorHandler(state, action);

        // WebPages Api Options
        case actionTypes.GET_COMMON_WEB_PAGES_OPTION_RESPONSE: return webPagesCommonOptionResponseHandler(state, action);

        // Insurance Plans Api Options
        case actionTypes.GET_COMMON_INSURANCE_PLANS_OPTION_RESPONSE: return insurancePlansCommonOptionResponseHandler(state, action);
        case actionTypes.GET_ALL_OPTION_RESPONSE: return getAllOptionResponseHandler(state, action);

        //UTM Options 
        case actionTypes.GET_UTM_OPTIONS_RESPONSE: return utmToggleOptionResponseHandler(state, action);

        // get Procedures option array
        case actionTypes.GET_HEADER_PROCEDURES_OPTION_RESPONSE: return headerProceduresOptionResponseHandler(state, action);
        case actionTypes.GET_HEADER_PROCEDURES_OPTION_ERROR: return headerProceduresOptionErrorHandler(state, action);


        // Initial Api Call
        case actionTypes.GET_CHART_ANALYTICS_REQUEST: return chartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_CHART_ANALYTICS_RESPONSE: return chartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_CHART_ANALYTICS_ERROR: return chartAnalyticsErrorHandler(state, action);

        // ! --------------------------------- Line Chart ---------------------------------
        // MultiLineChart Initial Api Call
        case actionTypes.GET_MULTILINE_CHART_ANALYTICS_REQUEST: return multiLineChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_MULTILINE_CHART_ANALYTICS_RESPONSE: return multiLineChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_MULTILINE_CHART_ANALYTICS_ERROR: return multiLineChartAnalyticsErrorHandler(state, action);

        // showed & Upcoming Consult Report Api Call
        case actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_REQUEST: return showedAndUpcomingConsultReportRequestHandler(state, action);
        case actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_RESPONSE: return showedAndUpcomingConsultReportResponseHandler(state, action);
        case actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_REPORT_ERROR: return showedAndUpcomingConsultReportErrorHandler(state, action);
        case actionTypes.TOGGLE_COMBINE_LEADS_BTN: return togglecombineleadsBtnHandler(state, action); // Toggle btn

        // showed & Upcoming Consult Report Api Call
        case actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_REQUEST: return showedAndUpcomingConsultAppointmentRequestHandler(state, action);
        case actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_RESPONSE: return showedAndUpcomingConsultAppointmentResponseHandler(state, action);
        case actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_ERROR: return showedAndUpcomingConsultAppointmentErrorHandler(state, action);
        case actionTypes.TOGGLE_COMBINE_LEADS_APPOINTMENT_BTN: return togglecombineleadsAppointmentBtnHandler(state, action); // Toggle btn

        //daily_open_action_items_report
        case actionTypes.GET_DAILY_OPEN_ACTION_ITEMS_REPORT_REQUEST: return dailyOpenActionItemsReportRequestHandler(state, action);
        case actionTypes.GET_DAILY_OPEN_ACTION_ITEMS_REPORT_RESPONSE: return dailyOpenActionItemsReportRequestResponseHandler(state, action);
        case actionTypes.GET_DAILY_OPEN_ACTION_ITEMS_REPORT_ERROR: return dailyOpenActionItemsReportErrorHandler(state, action); // Toggle btn


        // 
        case actionTypes.GET_AVALIABLE_APPOINTMENT_LINE_CHART_REQUEST: return avaliableAppointmentLineChartRequestHandler(state, action);
        case actionTypes.GET_AVALIABLE_APPOINTMENT_LINE_CHART_RESPONSE: return avaliableAppointmentLineChartResponseHandler(state, action);
        case actionTypes.GET_AVALIABLE_APPOINTMENT_LINE_CHART_ERROR: return avaliableAppointmentLineChartErrorHandler(state, action);


        // ! Multivalue Axis data (multiple y axis)
        case actionTypes.GET_WEBSITE_CONVERSION_REQUEST: return websiteConversionRequestHandler(state, action);
        case actionTypes.GET_WEBSITE_CONVERSION_RESPONSE: return websiteConversionResponseHandler(state, action);
        case actionTypes.GET_WEBSITE_CONVERSION_ERROR: return websiteConversionErrorHandler(state, action);

        // ! --Get Alerts Data
        case actionTypes.GET_ALERTS_REQUEST: return alertRequestHandler(state, action)
        case actionTypes.GET_ALERTS_RESPONSE: return alertResponseHandler(state, action);
        case actionTypes.GET_ALERTS_ERROR: return alertResponseHandlerErrorHandler(state, action);
        case actionTypes.GET_ALERTS_COUNT: return alertCountHandler(state, action);

        // ! --------------------------------- Pyramid Chart ---------------------------------
        // ScheduleFirst Pyramid Chart Initial Api Call
        case actionTypes.GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST: return schedulFirstPyramidChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE: return schedulFirstPyramidChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR: return schedulFirstPyramidChartAnalyticsErrorHandler(state, action);

        // InsuranceFirst Pyramid Chart Initial Api Call
        case actionTypes.GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST: return insuranceFirstPyramidChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE: return insuranceFirstPyramidChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR: return insuranceFirstPyramidChartAnalyticsErrorHandler(state, action);

        // ! --------------------------------- Gauge Chart ---------------------------------
        // Get Practice Location Api Call
        case actionTypes.GET_PRACTICE_LOCATIONS_API_REQUEST: return getPracticeLocationsApiRequestHandler(state, action);
        case actionTypes.GET_PRACTICE_LOCATIONS_API_RESPONSE: return getPracticeLocationsApiResponseHandler(state, action);
        case actionTypes.GET_PRACTICE_LOCATIONS_API_ERROR: return getPracticeLocationsApiErrorHandler(state, action);
        // Get Surgeons Api Call
        case actionTypes.GET_SURGEONS_API_REQUEST: return getSurgeonsRequestHandler(state, action);
        case actionTypes.GET_SURGEONS_API_RESPONSE: return getSurgeonsApiResponseHandler(state, action);
        case actionTypes.GET_SURGEONS_API_ERROR: return getSurgeonsApiErrorHandler(state, action);
        // Get Gauge Chart Api Call
        case actionTypes.GET_GAUGECHART_API_REQUEST: return getGaugeChartRequestHandler(state, action);
        case actionTypes.GET_GAUGECHART_API_RESPONSE: return getGaugeChartResponseHandler(state, action);
        case actionTypes.GET_GAUGECHART_API_ERROR: return getGaugeChartErrorHandler(state, action);

        // ! --------------------------------- Heat Map Chart ---------------------------------
        // Get Booked Appointment Heat Map Chart API Cal
        case actionTypes.GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST: return bookedAppointmentHeatMapChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE: return bookedAppointmentHeatMapChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_BOOKED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR: return bookedAppointmentHeatMapChartAnalyticsErrorHandler(state, action);

        case actionTypes.GET_SELECTED_LOCATION_BOOKED_RESPONSE: return selectedLocationBookedResponseHandler(state, action);
        // Get Leads Option Array
        case actionTypes.GET_LEADS_OPTION_BOOKED_REQUEST: return leadsOptionBookedRequestHandler(state, action);
        case actionTypes.GET_LEADS_OPTION_BOOKED_RESPONSE: return leadsOptionBookedResponseHandler(state, action);
        case actionTypes.GET_LEADS_OPTION_BOOKED_ERROR: return leadsOptionBookedErrorHandler(state, action);

        // Get Showed Appointment Heat Map Chart API Cal
        case actionTypes.GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST: return showedAppointmentHeatMapChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE: return showedAppointmentHeatMapChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_SHOWED_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR: return showedAppointmentHeatMapChartAnalyticsErrorHandler(state, action);

        case actionTypes.GET_SELECTED_LOCATION_SHOWED_RESPONSE: return selectedLocationShowedResponseHandler(state, action);
        // Get Leads Option Array
        case actionTypes.GET_LEADS_OPTION_SHOWED_REQUEST: return leadsOptionShowedRequestHandler(state, action);
        case actionTypes.GET_LEADS_OPTION_SHOWED_RESPONSE: return leadsOptionShowedResponseHandler(state, action);
        case actionTypes.GET_LEADS_OPTION_SHOWED_ERROR: return leadsOptionShowedErrorHandler(state, action);

        // Get Show Rate Appointment Heat Map Chart API Cal
        case actionTypes.GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_REQUEST: return showRateAppointmentHeatMapChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_RESPONSE: return showRateAppointmentHeatMapChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_SHOW_RATE_APPOINTMENT_HEATMAP_CHART_ANALYTICS_ERROR: return showRateAppointmentHeatMapChartAnalyticsErrorHandler(state, action);

        case actionTypes.GET_SELECTED_LOCATION_SHOW_RATE_RESPONSE: return selectedLocationShowRateResponseHandler(state, action);
        // Get Leads Option Array
        case actionTypes.GET_LEADS_OPTION_SHOW_RATE_REQUEST: return leadsOptionShowRateRequestHandler(state, action);
        case actionTypes.GET_LEADS_OPTION_SHOW_RATE_RESPONSE: return leadsOptionShowRateResponseHandler(state, action);
        case actionTypes.GET_LEADS_OPTION_SHOW_RATE_ERROR: return leadsOptionShowRateErrorHandler(state, action);

        // ! --------------------------------- Funnel Chart ---------------------------------
        // Get Insurance Report Funnel Chart API Cal
        case actionTypes.GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_REQUEST: return insuranceReportFunnelChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_RESPONSE: return insuranceReportFunnelChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_INSURANCE_REPORT_FUNNEL_CHART_ANALYTICS_ERROR: return insuranceReportFunnelChartAnalyticsErrorHandler(state, action);

        // Get Leads Option Array
        case actionTypes.GET_LEADS_OPTION_INSURANCE_FUNNEL_REQUEST: return leadsOptionInsuranceFunnelRequestHandler(state, action);
        case actionTypes.GET_LEADS_OPTION_INSURANCE_FUNNEL_RESPONSE: return leadsOptionInsuranceFunnelResponseHandler(state, action);
        case actionTypes.GET_LEADS_OPTION_INSURANCE_FUNNEL_ERROR: return leadsOptionInsuranceFunnelErrorHandler(state, action);

        // ! --------------------------------- Phone Flow ---------------------------------
        // ScheduleFirst Pyramid Chart Initial Api Call
        case actionTypes.GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST: return phoneFlowSchedulFirstPyramidChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE: return phoneFlowSchedulFirstPyramidChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_PHONEFLOW_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR: return phoneFlowSchedulFirstPyramidChartAnalyticsErrorHandler(state, action);

        // InsuranceFirst Pyramid Chart Initial Api Call
        case actionTypes.GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST: return phoneFlowInsuranceFirstPyramidChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE: return phoneFlowInsuranceFirstPyramidChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_PHONEFLOW_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR: return phoneFlowInsuranceFirstPyramidChartAnalyticsErrorHandler(state, action);

        // ! -------------------------------- Google Analytics --------------------------------
        case actionTypes.GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_REQUEST: return showedAppointmentGoogleAnalyticsChartRequestHandler(state, action);
        case actionTypes.GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_RESPONSE: return showedAppointmentGoogleAnalyticsChartResponseHandler(state, action);
        case actionTypes.GET_SHOWED_APPOINTMENT_GOOGLE_ANALYTICS_CHART_ERROR: return showedAppointmentGoogleAnalyticsChartErrorHandler(state, action);

        // ! --------------------------------- Referral Report ---------------------------------
        // ScheduleFirst Pyramid Chart Initial Api Call
        case actionTypes.GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_REQUEST: return referralReportSchedulFirstPyramidChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE: return referralReportSchedulFirstPyramidChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_REFERRALREPORT_SCHEDULFIRST_PYRAMID_CHART_ANALYTICS_ERROR: return referralReportSchedulFirstPyramidChartAnalyticsErrorHandler(state, action);

        // InsuranceFirst Pyramid Chart Initial Api Call
        case actionTypes.GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_REQUEST: return referralReportInsuranceFirstPyramidChartAnalyticsRequestHandler(state, action);
        case actionTypes.GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_RESPONSE: return referralReportInsuranceFirstPyramidChartAnalyticsResponseHandler(state, action);
        case actionTypes.GET_REFERRALREPORT_INSURANCEFIRST_PYRAMID_CHART_ANALYTICS_ERROR: return referralReportInsuranceFirstPyramidChartAnalyticsErrorHandler(state, action);

        // ! --------------------------------- Bar Chart ---------------------------------
        case actionTypes.GET_CLICKED_SCHEDULING_BAR_CHART_REQUEST: return clickedSchedulingBarChartRequestHandler(state, action);
        case actionTypes.GET_CLICKED_SCHEDULING_BAR_CHART_RESPONSE: return clickedSchedulingBarChartResponseHandler(state, action);
        case actionTypes.GET_CLICKED_SCHEDULING_BAR_CHART_ERROR: return clickedSchedulingBarChartErrorHandler(state, action);

        case actionTypes.GET_SELECTED_LOCATION_CLICKED_BAR_RESPONSE: return selectedLocationClickedBarResponseHandler(state, action);

        // ! --------------------------------- Stacked Column 100 Chart ---------------------------------
        case actionTypes.GET_FILLED_VS_UNFILLED_APPOINTMENT_REQUEST: return filledVsUnfilledAppointmentRequestHandler(state, action);
        case actionTypes.GET_FILLED_VS_UNFILLED_APPOINTMENT_RESPONSE: return filledVsUnfilledAppointmentResponseHandler(state, action);
        case actionTypes.GET_FILLED_VS_UNFILLED_APPOINTMENT_ERROR: return filledVsUnfilledAppointmentErrorHandler(state, action);

        case actionTypes.GET_SELECTED_LOCATION_FILLED_VS_UNFILLED_RESPONSE: return selectedLocationFilledVsUnfilledResponseHandler(state, action);

        // ! --------------------------------- Stacked Column Chart ---------------------------------
        // Showed Consults
        case actionTypes.GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_REQUEST: return showedConsultsByLeadSourceRequestHandler(state, action);
        case actionTypes.GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_RESPONSE: return showedConsultsByLeadSourceResponseHandler(state, action);
        case actionTypes.GET_SHOWED_CONSULTS_BY_LEAD_SOURCE_ERROR: return showedConsultsByLeadSourceErrorHandler(state, action);

        case actionTypes.GET_SELECTED_LOCATION_SHOWED_CONSULTS_BY_LEAD_RESPONSE: return selectedLocationShowedConsultsByLeadSourceResponseHandler(state, action);

        // Upcoming Consults
        case actionTypes.GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_REQUEST: return upcomingConsultsByLeadSourceRequestHandler(state, action);
        case actionTypes.GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_RESPONSE: return upcomingConsultsByLeadSourceResponseHandler(state, action);
        case actionTypes.GET_UPCOMING_CONSULTS_BY_LEAD_SOURCE_ERROR: return upcomingConsultsByLeadSourceErrorHandler(state, action);

        case actionTypes.GET_SELECTED_LOCATION_UPCOMING_CONSULTS_BY_LEAD_RESPONSE: return selectedLocationUpcomingConsultsByLeadSourceResponseHandler(state, action);

        // showed Vs NoShow Vs Cancelled Appointment
        case actionTypes.GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_REQUEST: return showedVsNoShowVsCancelledAppointmentRequestHandler(state, action);
        case actionTypes.GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_RESPONSE: return showedVsNoShowVsCancelledAppointmentResponseHandler(state, action);
        case actionTypes.GET_SHOWED_VS_NOSHOW_VS_CANCELLED_APPOINTMENT_ERROR: return showedVsNoShowVsCancelledAppointmentErrorHandler(state, action);

        case actionTypes.GET_SELECTED_LOCATION_SHOWED_VS_NOSHOW_VS_CANCELLED_RESPONSE: return selectedLocationShowedVsNoShowVsCancelledResponseHandler(state, action);

        // ! --------------------------------- Pie Chart ---------------------------------
        // Showed Consults
        case actionTypes.GET_SHOWED_CONSULTS_PIE_CHART_REQUEST: return getShowedConsultsPieChartRequestHandler(state, action);
        case actionTypes.GET_SHOWED_CONSULTS_PIE_CHART_RESPONSE: return getShowedConsultsPieChartResponseHandler(state, action);
        case actionTypes.GET_SHOWED_CONSULTS_PIE_CHART_ERROR: return getShowedConsultsPieChartErrorHandler(state, action);

        case actionTypes.GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_REQUEST: return getFilledVsUnfilledFutureAppointmentSlotsPieCharRequestHandler(state, action);
        case actionTypes.GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_RESPONSE: return getFilledVsUnfilledFutureAppointmentSlotsPieCharResponseHandler(state, action);
        case actionTypes.GET_FILLED_VS_UNFILLED_FUTURE_APPOINTMENT_SLOTS_PIE_CHART_ERROR: return getFilledVsUnfilledFutureAppointmentSlotsPieCharErrorHandler(state, action);

        case actionTypes.GET_SELECTED_LOCATION_SHOWED_CONSULTS_PIE_CHART_RESPONSE: return selectedLocationShowedConsultsPieChartResponseHandler(state, action);



        // Upcoming Consults
        case actionTypes.GET_UPCOMING_CONSULTS_PIE_CHART_REQUEST: return getUpcomingConsultsPieChartRequestHandler(state, action);
        case actionTypes.GET_UPCOMING_CONSULTS_PIE_CHART_RESPONSE: return getUpcomingConsultsPieChartResponseHandler(state, action);
        case actionTypes.GET_UPCOMING_CONSULTS_PIE_CHART_ERROR: return getUpcomingConsultsPieChartErrorHandler(state, action);

        // ! ---------------------------------click To 1---------------------------------
        case actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_REQUEST: return getClickToCallScheduledShowedConsultsRequestHandler(state, action);
        case actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_RESPONSE: return getClickToCallScheduledShowedConsultsResponseHandler(state, action);
        case actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_ERROR: return getClickToCallScheduledShowedConsultsHandler(state, action);

        // ! ---------------------------------click To 2---------------------------------
        case actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_REQUEST: return getClicktocallscheduledshowedconsultsbyleadsourceRequestHandler(state, action);
        case actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_RESPONSE: return getClicktocallscheduledshowedconsultsbyleadsourceResponseHandler(state, action)
        case actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_By_LEADSOURCE_ERROR: return getClicktocallscheduledshowedconsultsbyleadsourceErrorHandler(state, action)

        // ! ---------------------------------click To 3---------------------------------
        case actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_REQUEST: return getClicktocallscheduledshowedconsultsformsubmissiondateRequestHandler(state, action);
        case actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_RESPONSE: return getClicktocallscheduledshowedconsultsformsubmissiondateResponseHandler(state, action);
        case actionTypes.GET_CLICK_TO_CALL_SCHEDULED_SHOWED_CONSULTS_FORMSUBMISSIONDATE_ERROR: return getClicktocallscheduledshowedconsultsformsubmissiondateErrorHandler(state, action);


        // ! ---------------------------------click To 4---------------------------------
        case actionTypes.GET_CLICK_TO_CALL_FUNNEL_REQUEST: return getClicktocallfunnelRequestHandler(state, action);
        case actionTypes.GET_CLICK_TO_CALL_FUNNEL_RESPONSE: return getClicktocallfunnelResponseHandler(state, action);
        case actionTypes.GET_CLICK_TO_CALL_FUNNEL_ERROR: return getClicktocallfunnelErrorHandler(state, action);

        // ! -------------------------------- FOLLOW_UP_IMPACT_REPORT_CHART -------------------------------
        case actionTypes.GET_FOLLOW_UP_IMPACT_REPORT_CHART_REQUEST: return getFollowUpImpactReportChartRequestHandler(state, action);
        case actionTypes.GET_FOLLOW_UP_IMPACT_REPORT_CHART_RESPONSE: return getFollowUpImpactReportChartResponseHandler(state, action);
        case actionTypes.GET_FOLLOW_UP_IMPACT_REPORT_CHART_ERROR: return getFollowUpImpactReportChartErrorHandler(state, action);
        // ! ---------------------------------click To 5---------------------------------
        case actionTypes.GET_CLICK_TO_CALL_WEBSITE_CONVERSION: return getClicktocallwebsiteConversionRequestHandler(state, action);
        case actionTypes.GET_CLICK_TO_CALL_WEBSITE_RESPONSE: return getClicktocallwebsiteConversionResponseHandler(state, action);
        case actionTypes.GET_CLICK_TO_CALL_WEBSITE_ERROR: return getClicktocallwebsiteConversionErrorHandler(state, action);

        // ! -------------------------------- open_action_item_average_turn_around_time --------------------------------
        case actionTypes.GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_REQUEST: return getOpenActionItemAverageTurnAroundTimeRequestHandler(state, action);
        case actionTypes.GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_RESPONSE: return getOpenActionItemAverageTurnAroundTimeResponseHandler(state, action);
        case actionTypes.GET_OPEN_ACTION_ITEM_AVERAGE_TURN_AROUND_TIME_ERROR: return getOpenActionItemAverageTurnAroundTimeErrorHandler(state, action);

        // ! -------------------------------- open_action_items_resolved_by_user --------------------------------
        case actionTypes.GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST: return getOpenActionItemsResolvedByUserRequestHandler(state, action);
        case actionTypes.GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST_RESPONSE: return getOpenActionItemsResolvedByUserResponseHandler(state, action);
        case actionTypes.GET_OPEN_ACTION_ITEMS_RESOLVED_BY_USER_REQUEST_ERROR: return getOpenActionItemsResolvedByUserErrorHandler(state, action);

        // ! -------------------------------- facebook_ads_cost_per_conversion ------------------------------
        case actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_REQUEST: return getGetFacbookAdsCostPerConversionRequestHandler(state, action);
        case actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_RESPONS: return getGetFacbookAdsCostPerConversionResponseHandler(state, action);
        case actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_ERROR: return getGetFacbookAdsCostPerConversionErrorHandler(state, action);

        // ! -------------------------------- facebook_ads_cost_per_conversion_funnel ------------------------------
        case actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_REQUEST: return getFacebookAdsCostPerConversionFunnelRequestHandler(state, action);
        case actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_RESPONS: return getFacebookAdsCostPerConversionFunnelResponseHandler(state, action);
        case actionTypes.GET_FACEBOOK_ADS_COST_PER_CONVERSION_FUNNEL_ERROR: return getFacebookAdsCostPerConversionFunnelErrorHandler(state, action);

        // ! -------------------------------- google_ads_cost_per_conversion ------------------------------
        case actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_REQUEST: return getGetGoogleAdsCostPerConversionRequestHandler(state, action);
        case actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_RESPONS: return getGetGoogleAdsCostPerConversionResponseHandler(state, action);
        case actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_ERROR: return getGetGoogleAdsCostPerConversionErrorHandler(state, action);

        // ! -------------------------------- google_ads_cost_per_conversion_funnel ------------------------------
        case actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_REQUEST: return getgoogleAdsCostPerConversionFunnelRequestHandler(state, action);
        case actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_RESPONS: return getgoogleAdsCostPerConversionFunnelResponseHandler(state, action);
        case actionTypes.GET_GOOGLE_ADS_COST_PER_CONVERSION_FUNNEL_ERROR: return getgoogleAdsCostPerConversionFunnelErrorHandler(state, action);

        // ! -------------------------------- Top_Performing_Ads --------------------------------------------------
        case actionTypes.GET_TOP_PERFORMING_ADS_REQUEST: return getTopPerformingAdsRequestHandler(state, action);
        case actionTypes.GET_TOP_PERFORMING_ADS_RESPONSE: return getTopPerformingAdsResponseHandler(state, action);
        case actionTypes.GET_TOP_PERFORMING_ADS_SELECTED_GOOGLE_SOURCE_OPTIONS: return getTopPerFormingAdsSelectedGoogleSourceOptionsHandler(state, action);
        case actionTypes.GET_TOP_PERFORMING_ADS_SELECTED_FACEBOOK_SOURCE_OPTIONS: return getTopPerFormingAdsSelectedFacebookeSourceOptionsHandler(state, action);
        // case actionTypes.GET_TOP_PERFORMING_ADS_SELECTED_INSTAGRAM_SOURCE_OPTIONS: return getTopPerFormingAdsSelectedInstagramSourceOptionsHandler(state, action);
        case actionTypes.GET_TOP_PERFORMING_ADS_SELECTED_GROP_OPTIONS: return getTopPerFormingAdsSelectedGropOptionsHandler(state, action);

        // ! -------------------------------- Non_Performing_Ads --------------------------------------------------
        case actionTypes.GET_NON_COVERTING_ADS_REQUEST: return getNonConvertingAdsRequestHandler(state, action);
        case actionTypes.GET_NON_COVERTING_ADS_RESPONSE: return getNonConvertingAdsResponseHandler(state, action);
        case actionTypes.GET_NON_COVERTING_ADS_SELECTED_GOOGLE_SOURCE_OPTIONS: return getNonConvertingAdsSelectedGoogleSourceOptionsHandler(state, action);
        case actionTypes.GET_NON_COVERTING_ADS_SELECTED_FACEBOOK_SOURCE_OPTIONS: return getNonConvertingAdsSelectedFacebookeSourceOptionsHandler(state, action);





        // case actionTypes.GET_NON_COVERTING_ADS_SELECTED_INSTAGRAM_SOURCE_OPTIONS: return getNonConvertingAdsSelectedInstagramSourceOptionsHandler(state, action);
        case actionTypes.GET_NON_COVERTING_ADS_SELECTED_GROP_OPTIONS: return getNonConvertingAdsSelectedGropOptionsHandler(state, action);

        // ! -------------------------------- Google_Ads_Form_Submission_Date --------------------------------------------------
        case actionTypes.GOOGLE_ADS_FORM_SUBMISSION_DATE: return GetGoogleAdsAuthDateHandler(state, action);
        // ! -------------------------------- Meta_Ads_Form_Submission_Date --------------------------------------------------
        case actionTypes.META_ADS_FORM_SUBMISSION_DATE: return GetMetaAdsAuthDateHandler(state, action);

        //!------------------ facebook_Search_Box  -----------------------
        case actionTypes.FACEBOOK_SEARCH_BOX: return GetFacebookSearchBoxHandler(state, action);

        //! -------------------------------- Conversion_Funnel_By_Insurance_company ------------------------------
        case actionTypes.GET_CONVERSION_FUNNEL_BY_INSURANCE_COMPANY_REQUEST: return GetConversionFunnelByInsuranceCompanyRequestHandler(state, action);
        case actionTypes.GET_CONVERSION_FUNNEL_BY_INSURANCE_COMPANY_RESPONSE: return GetConversionFunnelByInsuranceCompanyResponseHandler(state, action);
        case actionTypes.GET_CONVERSION_FUNNEL_BY_INSURANCE_COMPANY_ERROR: return GetConversionFunnelByInsuranceCompanyErrorHandler(state, action);


        //! -------------------------------- Schedule_First_Funnel_Report_Over_Time ------------------------------
        case actionTypes.GET_SCHEDULE_FIRST_FUNNEL_REPORT_OVER_TIME_REQUEST: return GetScheduleFirstFunnelReportOverTimeRequestHandler(state, action);
        case actionTypes.GET_SCHEDULE_FIRST_FUNNEL_REPORT_OVER_TIME_RESPONSE: return getScheduleFirstFunnelReportOverTimeResponse(state, action);
        case actionTypes.GET_SCHEDULE_FIRST_FUNNEL_REPORT_OVER_TIME_ERROR: return GetScheduleFirstFunnelReportOverTimeErrorHandler(state, action);

        //!-------------------------------Insurance_First_Funnel_Report_Over_Time --------------------------------
        case actionTypes.GET_INSURANCE_FIRST_FUNNEL_REPORT_OVER_TIME_REQUEST: return GetInsuranceFirstFunnelReportOverTimeRequestHandler(state, action);
        case actionTypes.GET_INSURANCE_FIRST_FUNNEL_REPORT_OVER_TIME_RESPONSE: return getInsuranceFirstFunnelReportOverTimeResponse(state, action);
        case actionTypes.GET_INSURANCE_FIRST_FUNNEL_REPORT_OVER_TIME_ERROR: return GetInsuranceFirstFunnelReportOverTimeErrorHandler(state, action);


        // showed & Upcoming Consult Report Api Call
        case actionTypes.GET_SHOWED_AND_UPCOMING_CONSULT_APPOINTMENT_REQUEST: return showedAndUpcomingConsultAppointmentRequestHandler(state, action);


        case actionTypes.GET_SELECTED_LOCATION_UPCOMING_CONSULTS_PIE_CHART_RESPONSE: return selectedLocationUpcomingConsultsPieChartResponseHandler(state, action);

        // On change text center dropdown
        case actionTypes.ON_CHANGE_TEXT_CENTER: return onChangeTextCenterHandler(state, action);

        // Common on change Date Filter
        case actionTypes.COMMON_HANDLE_CHANGE: return commonOnChangeHandler(state, action);

        case actionTypes.CHANGE_CURRENT_PAGE: return changeCurrentPageHandler(state, action);

        default: return state;
    }
};

export default analyticsReducer;
